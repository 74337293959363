import React, { useEffect, useState } from 'react'
import { Skeleton, Zoom } from '@mui/material'
import { useSelector } from 'react-redux'
import { Image } from '../Image/Image.js'

// ${user?.practiceLogoPath}

export const PracticeLogo = ({ logoProps }) => {
  const { user } = useSelector((state) => state?.user);
  const [logoPath, setLogoPath] = useState(``);

  useEffect(() => {
    if (user?.practiceLogoPath) {
      setLogoPath(user?.practiceLogoPath)
    }
  }, [user?.practiceLogoPath])

  return (
    !logoPath ? <Skeleton {...logoProps} />
      : <Zoom in={!!logoPath} mountOnEnter unmountOnExit>
        <div>
          <Image imgProps={{
            ...logoProps, src: logoPath
          }} />
        </div>
      </Zoom>
  )
}
