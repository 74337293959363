import React, { useState, useEffect } from 'react';
import { postRequest, PATIENT_SEND_TEXT_MESSAGE_URL } from '../../../../../../../crud/crud';
import { useSelector, useDispatch } from 'react-redux';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import {
  setAllMessages, socketResponse, smsResponse, textError,
  communcicationWithPatient
} from 'src/redux/patient/patientActions';
import { IconButton, TextField, Grid, Box } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/Upload';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { useLocation } from 'react-router-dom';
import { convertToBase64, onKeyUpCredentialsHandler, shallowValueSetter } from 'src/utils';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';

export const SendMsg = ({ patientNumber, patientId, twilioOutGoingSMS, conditionalStyles,
  setSingleMsg, chatBox }) => {

  const [msg, setMsg] = useState(``);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setOpenTemplatesGate] = useState(false);
  const dispatchRedux = useDispatch();
  const messages = useSelector((state) => state.patient.messages);
  const msgApiFlags = useSelector((state) => state.patient.messageLoadingFlag);
  const location = useLocation();
  const [phraseId, setPhraseId] = useState(null);
  const [phraseScope, setPhraseScope] = useState(null);
  //  for smartPhrase hide and show

  const { timer, hideTimer } = useSelector((state) => state?.patient);
  const { audioCall } = useSelector((state) => state?.timer);

  useEffect(() => {
    newSocket.on(`sendMMS`, (data) => {
      let id = data?._uniqueId;
      let error = data?.error;
      if (!error) {
        dispatchRedux(socketResponse({ id: id, data: data }));
      } else {
        dispatchRedux(socketResponse({ id: id, error: error }));
      }
    });
  }, []);

  const setSingleMessageHandler = () => {
    if (setSingleMsg) {
      setSingleMsg();
    }
  };

  useEffect(() => {
    const handleReply = (data) => {
      if (data?.message) {
        if (location.pathname === `/patient` && patientId == data?._patientId) {
          setSingleMessageHandler();
          dispatchRedux({ type: `SET_MESSAGES_REPLY`, payload: data });
        }
      }
    };
    newSocket.on(`smsReply`, handleReply);

    return () => {
      newSocket.off(`smsReply`, handleReply);
    };
  }, [patientId]);

  const errorHandler = (err) => {
    if (err?.response) dispatchRedux(textError({ error: true, _uniqueId: err.response?.data?._uniqueId }));
  };
  const sendMms = (base46FileParam) => {
    let _uniqueId = Date.now().toString(36).substring(2, 15) + Date.now().toString(36).substring(2, 15);
    let tempMsg = {
      messageDirection: `outbound`,
      type: `MMS`,
      file: base46FileParam?.image,
      fileTtype: base46FileParam.type,
      _uniqueId: _uniqueId,
      status: `pending`,
    };

    dispatchRedux(setAllMessages([...messages, tempMsg]));
    let payLoad = {
      media: base46FileParam?.image,
      _uniqueId: _uniqueId,
    };

    if (timer?.toggleButton || hideTimer) {
      dispatchRedux(communcicationWithPatient({ chatting: true, text: `View graphs and reports + Media Message` }));
    }
    setSingleMessageHandler();
    postRequest(PATIENT_SEND_TEXT_MESSAGE_URL + patientId, payLoad)
      .then((res) => {
        twilioOutGoingSMS(res?.data);
        setMsg(``);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const onlySpaces = (str) => {
    return /^\s*$/.test(str);
  };

  useEffect(() => {
    if (audioCall) {
      setMsg(``);
    }
  }, [audioCall])

  const sendMsgHandler = (e) => {
    let _uniqueId = Date.now();
    if (e.keyCode === 8 && msg.length < 1) {
      e.target.style.height = 0;
    }
    if (e.keyCode === 46 && msg.length < 1) {
      e.target.style.height = 0;
    }
    if (e.target.style.height.split(`p`)[0] < 140) {
      e.target.style.height = 0;
      e.target.style.height = e.target.scrollHeight + `px`;
    }

    if (e.keyCode === 13 && msg.length > 1) {
      e.preventDefault();
      let date = new Date();
      let tempMsg = {
        messageDirection: `outbound`,
        message: msg,
        type: `SMS`,
        date: date,
        _uniqueId: _uniqueId,
        status: `pending`,
      };
      if (!onlySpaces(msg) && !e.shiftKey && e.keyCode === 13 && msg !== `#`) {
        if (patientNumber) {
          setSingleMessageHandler();
          dispatchRedux(setAllMessages([...messages, tempMsg]));
          let temp = messages;
          e.target.style.height = 0;

          let payLoad = {
            doctorMessage: msg,
            _uniqueId: _uniqueId,
            phraseId: phraseId,
            phraseScope: phraseScope,
          };
          if (timer?.toggleButton || hideTimer) {
            dispatchRedux(communcicationWithPatient({ chatting: true, text: `View graphs and reports + Text Message` }));
          }

          postRequest(PATIENT_SEND_TEXT_MESSAGE_URL + patientId, payLoad)
            .then((res) => {
              twilioOutGoingSMS(res?.data);
              setMsg(``);
              setPhraseId(null);
              setPhraseScope(null);
              if (temp.length) {
                dispatchRedux(smsResponse(res.data.message));
              } else dispatchRedux(setAllMessages([res.data.message]));
            })
            .catch((err) => {
              setPhraseId(null);
              setPhraseScope(null);
              if (err.response) {
                errorHandler(err);
              }
            });

          setMsg(``);
        } else errorToast(`Patient doest not has a valid phone number`)
      }
    }
  };

  const fileHandleChange = async (e) => {
    e.persist();
    let img = `image`;
    let video = `video`;
    let aud = `audio`;
    let app = `pdf`;
    let csv = `text/csv`;
    const file = e.target.files[0];

    let type = file?.type;
    let validateImg = type?.includes(img);
    let validateAud = type?.includes(aud);
    let validateVid = type?.includes(video);
    let validateApp = type?.includes(app);
    let validateCsv = type?.includes(csv);

    let size = file?.size / 1000;
    if (!validateImg && !validateAud && !validateVid && !validateApp && !validateCsv) {
      errorToast(`Only audio,video,image,pdf and csv files are accepted!`);
      e.target.value = ``;
    } else if (size && size > 3000) {
      errorToast(`File Must be less than 3MB`);
      e.target.value = ``;
    } else {
      const base64 = await convertToBase64(file, false);

      sendMms({ image: base64, type: file.type });

      e.target.value = ``;
    }
  };

  useEffect(() => {
    if (chatBox?.current) chatBox.current.scrollTop = chatBox.current.scrollHeight;
  }, [chatBox]);

  const style = { bottom: `62px`, width: `100%`, ...conditionalStyles };
  return (
    <Box sx={{ width: `100%` }}>
      <SmartPhraseModal
        slectedPhraseId="patientMessengerPhrases"
        setNewNote={setMsg}
        newNote={msg}
        setPhraseId={setPhraseId}
        style={style}
        setPhraseScope={setPhraseScope}
      >
        <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setMsg} />
        <TextField
          fullWidth
          disabled={msgApiFlags?.loading || window.location == `/telemedicine` || patientId === undefined || null ? true : false}
          multiline
          variant="standard"
          maxRows={4}
          InputProps={{
            onChange: (e) => {
              let { value } = e.target;
              setMsg(value);
              shallowValueSetter({
                flag: openTemplatesGate,
                setValue: () => setShallowValue(value),
              });
            },
            onKeyDown: sendMsgHandler,
            onKeyUp: (e) =>
              onKeyUpCredentialsHandler({
                event: e,
                openTemplatesHandler: (value) => setOpenTemplatesGate(value),
              }),
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ verticalAlign: `top` }}>
                <>
                  {patientId && (
                    <Tooltip
                      arrow
                      sx={{ fontSize: `small` }}
                      title={
                        <Grid container>
                          <Grid item>
                            <UploadIcon sx={{ fontSize: `small` }} />
                            {` `}
                          </Grid>
                          <Grid item>Upload file</Grid>
                        </Grid>
                      }
                      placement="top-start"
                    >
                      <IconButton disabled={msgApiFlags?.loading ? true : false} aria-label="upload" size="medium" component="label">
                        <AttachmentIcon sx={{ fontSize: `large`, color: `rgb(22, 153, 197)` }} />
                        <input hidden type="file" onChange={(e) => fileHandleChange(e)} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              </InputAdornment>
            ),
          }}
          sx={{
            display: `table`,
            resize: `none`,
            boxShadow: `0 0 3px 0px #5e575794`,
            borderRadius: `3px`,
            width: `100%`,
            border: `none`,
            backgroundColor: `white`,
            outline: `none`,
          }}
          inputProps={{
            style: {
              minHeight: 50,
              fontSize: `small`,
            },
          }}
          autoComplete="off"
          placeholder="Send a Message..."
          value={msg}
        />
      </SmartPhraseModal>
    </Box>
  );
};
