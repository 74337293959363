import React, { useEffect, useRef, useState } from 'react';
import { Device } from 'twilio-client';
import { Box, Button, IconButton, MenuItem, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeFirstLetter, defFontColor, doIfSocketConnected, formatDurationHms,
  getTwillioVoiceAuth, returnIfArr
} from 'src/utils';
import { ConditionallyRenderWrapper } from './ConditionallyRenderWrapper';
import { ReactModalWrapper } from './ReactModalWrapper';
import { useHistory } from 'react-router-dom';
import { saveTimerReportId } from 'src/pages/home/modules/patient2/PatientComponent';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider';
import { endAudioCall, startAudioCall } from 'src/redux/timer/timerActions';
import { handleTimer, initializeStartAndEndTime, saveActiveConn } from 'src/redux/patient/patientActions';
import { newSocket } from './SocketWrapper';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { MuiPopOver } from '../components/MuiPopOver/MuiPopOver';
import { MuiSpinner } from '../components/MuiSpinner/MuiSpinner';
import { BlinkingAnimationWrapper } from './BlinkingAnimationWrapper';
import { MuiBtn } from '../components/Button/CustomBtn';
import RingingTone from 'src/assets/audio/ringing-151670.mp3';
import { momentWrapper } from 'src/momentWrapper';
import { store } from 'src/redux/store';
import { errorToast } from '../components/snackBar/toast';

let callInterval = null;
const twilioDevice = new Device();

export let incomCallAutoLogOutProt = null;

const buttonProps = ({ bgCol, hovBg, onClick }) => {
  return {
    variant: `contained`,
    onClick,
    sx: {
      backgroundColor: bgCol,
      textTransform: `none`,
      boxShadow: `none`,
      fontWeight: `bold`,
      '&:hover': {
        backgroundColor: hovBg,
      },
    },
  };
};

const accptBtn = {
  bgCol: `green`,
  hovBg: `darkgreen`,
};

const sendVmbtn = { bgCol: `#1699C5`, hovBg: `#078dba` };
const endOrIgnbtn = { bgCol: `#fc0707c4`, hovBg: `darkred` };
const blurBg = { backgroundColor: `#c7e1f7a1`, backdropFilter: `blur(5px)` };
const AcceptCallSuspenser = ({ isAcceptingCall }) => {
  return (
    <ConditionallyRenderWrapper con={isAcceptingCall} renderKey={`Accept`}>
      <MuiSpinner
        sx={{
          color: `white`,
        }}
        size={25}
        thickness={8}
      />
    </ConditionallyRenderWrapper>
  );
};

const IncomingVoiceCall = () => {
  const [callState, setCallState] = useState({ isRinging: false, isEastablished: false });
  const [openModal, setOpenModal] = useState(false);
  const [connection, setConnection] = useState({});
  const [isMuted, setIsMuted] = useState(false);
  const [listOfCalls, setListOfCalls] = useState([]);
  const [isAcceptingCall, setIsAcceptingCall] = useState(false);
  const { user, patient } = useSelector((state) => state);
  const {
    user: { _id: loggedUserId, _practiceId: practiceId } } = user;

  const {
    timer: { toggleButton: isMonitoringPat = false, startTime: startTimeAutoTimer },
  } = patient;

  const dispatch = useDispatch();
  const history = useHistory();
  const { isRinging, isEastablished } = callState;
  const callStatusHandler = (key, value, isWhole) => {
    if (isWhole) setCallState({ ...value });
    else setCallState((p) => ({ ...p, [key]: value }));
  };

  const mapCalls = ({ calls, patientId, key, value, extCon = () => false }) => {
    if (!Array.isArray(calls)) {
      return [];
    }

    return calls.map((call, ind) => {
      if (call?.from === patientId || extCon(call, ind)) {
        return { ...call, [key]: value };
      }
      return { ...call };
    });
  };

  const closeIfNoMoreCalls = (calls, shouldNotCloseModal) => {
    if (!calls?.length) {
      callStatusHandler(`isRinging`, false);
      if (!shouldNotCloseModal) setOpenModal(false);
    } else callStatusHandler(`isRinging`, true);
  };

  const resetState = ({ patientId = ``, shouldEndCall = false, from = ``, acceptCallFrom = `` }) => {
    const setCallsList = (calls) => {
      let filteredCalls = calls?.filter((call) => call?.from !== patientId);
      closeIfNoMoreCalls(filteredCalls);
      let isFirstInd = acceptCallFrom ? acceptCallFrom : 0;
      return [
        ...mapCalls({
          calls: filteredCalls,
          patientId: acceptCallFrom,
          key: `shouldDisplay`,
          value: true,
          extCon: (call, ind) => ind === isFirstInd,
        }),
      ];
    };
    setConnection({});
    setCallState({ isRinging: false, isEastablished: false });
    setIsMuted(false);
    if (acceptCallFrom) setIsAcceptingCall(true);
    else setIsAcceptingCall(false);
    if (from === `ignore` || from === `disconnect` || from === `vM` || acceptCallFrom) {
      setListOfCalls((calls) => {
        if (calls?.length === 1) {
          setOpenModal(false);
          return [];
        }
        return setCallsList(calls);
      });
    }

    if (shouldEndCall) dispatch(endAudioCall());
    if (from === `socetDisconnectivity`) setOpenModal(false)
  };

  const timeLogProt = (call) => {
    setIsAcceptingCall(true);
    newSocket.emit(`incommingVoiceCall`, { practiceId, status: `answered`, userId: loggedUserId, from: call?.from });
    setListOfCalls((calls) => {
      let elem = calls?.find((innerCall) => innerCall?.from === call?.from);
      return [{ ...elem, shouldDisplay: true, status: `answered` }];
    });
    dispatch(handleTimer({ status: false, timeEnd: momentWrapper().toISOString(), videoOrAudio: null, autoTimer: `true` }));
    if (isMonitoringPat) {
      const dispatchRed = () => {
        dispatch(initializeStartAndEndTime());
        if (isMonitoringPat) {
          saveTimerReportId({
            shouldNotCloseHideTimer: true,
            id: patient?.patientData?.data?._id,
            startTime: startTimeAutoTimer,
            customDesc: true,
          });
        }
      };
      dispatchRed();
    }
  };

  const acceptCall = (patientId) => {
    setListOfCalls((calls) => {
      if (calls?.length) {
        let filteredCall = calls?.find((call) => call?.from === patientId) || {}
        callStatusHandler(false, { isEastablished: true, isRinging: false }, true);
        history.push({ pathname: `/patient`, patientId, shouldNotStartTimer: true });
        dispatch(startAudioCall(`inComing`));
        return [{ ...filteredCall, status: `answered` }]
      }
      return []
    });

  };
  const endCall = (isIgnore, acceptCallFrom, callFrom) => {
    const callEndings = (shouldEndCall, cb) => {
      if (cb) cb();
      resetState({
        patientId: callFrom,
        shouldEndCall,
        from: isIgnore ? `ignore` : `endCall`,
        acceptCallFrom,
      });

    };
    if (isIgnore) {
      callEndings(false, false);
    } else if (isEastablished && connection?.disconnect) {
      callEndings(true, () => {
        connection.disconnect();

      });
    }
  };

  useEffect(() => {
    let ringingCall = listOfCalls?.find((call) => call?.shouldDisplay);
    let audioTone = new Audio(RingingTone);
    if (ringingCall?.status === `ringing` || listOfCalls?.length > 2) {
      audioTone.play();
      audioTone.loop = true;
    }
    return () => audioTone.pause();
  }, [listOfCalls]);




  useEffect(() => {
    const mapWithDisplay = (calls) => {
      let answeredCall = calls?.filter((call) => call?.status === `answered`);
      return [
        ...(calls && Array.isArray(calls)
          ? calls.map((call, ind) => {
            if (!answeredCall?.length && ind === 0) {
              return { ...call, ...(!call?.shouldDisplay && { shouldDisplay: true }) };
            }
            return { ...call };
          })
          : []),
      ];
    };
    newSocket.on(`incommingVoiceCall`, (call) => {
      doIfSocketConnected(() => {
        const {
          timer: { audioCall: isUserOnAudCall = false },
        } = store.getState();
        const {
          user: { _id: loggedUserId },
        } = user;
        const { isOnVideo: isUserOnVidCall } = patient;
        if (!isUserOnAudCall && !isUserOnVidCall) {
          setListOfCalls((calls) => {
            let isNotAnsweredByMe = typeof call?.userId === `string` && call?.userId !== loggedUserId;

            if ((calls?.length === 1 && isNotAnsweredByMe) || (!calls?.length && isNotAnsweredByMe)) {
              resetState({ patientId: ``, shouldEndCall: false, from: `disconnect` });
              return [];
            }

            if (!calls?.length && call?.status !== `answered`) {
              callStatusHandler(`isRinging`, true);
              setOpenModal(true);
              return [{ ...call, shouldDisplay: true, callConn: call }];
            }

            let hasBef = calls?.find((con) => con?.from === call?.from);

            if (hasBef) {
              return mapWithDisplay([
                ...returnIfArr(calls).filter((con) => {
                  return con?.from !== call?.from;
                }),
              ]);
            }

            return [...calls, { ...call, callConn: call }];
          });
        }
      })

    });
    newSocket.on(`incommingVoiceCallTerminated`, (callData) => {
      doIfSocketConnected(() => {
        const {
          patient,
          timer: { audioCall: isUserOnAudCall = false },
        } = store.getState();
        const { isOnVideo: isUserOnVidCall } = patient;
        if (!isUserOnAudCall && !isUserOnVidCall) {
          setListOfCalls((calls) => {
            let filteredCalls = calls?.filter((call) => call?.from !== callData?.from);
            let hasAnswereCall = calls?.filter((call) => call?.status === `answered`);
            let callsToSend = hasAnswereCall?.length ? [] : filteredCalls;
            closeIfNoMoreCalls(callsToSend, hasAnswereCall?.length);
            return mapWithDisplay(filteredCalls);
          });
        }
      })

    });
    twilioDevice.on(`offline`, () => {
      const { user: { user } } = store.getState()
      getTwillioVoiceAuth(user)
    })
    twilioDevice.on(`incoming`, (connection) => {
      setIsAcceptingCall(false);
      acceptCall(connection.customParameters.get(`PatientId`));
      setConnection(connection);
      callStatusHandler(false, { isEastablished: true, isRinging: false }, true);
      connection.accept();
      dispatch(saveActiveConn(twilioDevice));
    });
    twilioDevice.on(`disconnect`, (connection) => {
      resetState({ patientId: connection?.customParameters?.get(`PatientId`), shouldEndCall: true, from: `disconnect` });
      dispatch(saveActiveConn(false));
    });

    return () => {
      resetState({ from: `unmount` });
      setIsAcceptingCall(false);
      twilioDevice.destroy();
    };
  }, []);

  const renderTitle = () => {
    let incomingPat = listOfCalls?.find((call) => call?.shouldDisplay);
    if (isRinging || isEastablished) {
      let patient = capitalizeFirstLetter(`${incomingPat?.firstName} ${incomingPat?.lastName}`);
      if (isRinging) {
        return `Attention incoming voice call from ${patient}`;
      }
      return `Ongoing call with ${patient}`;
    }
  };

  let paraTitle = renderTitle();


  return (
    <SocketConnDisConProtection listOfCalls={listOfCalls} setListOfCalls={setListOfCalls} resetState={resetState}>
      <ReactModalWrapper
        hasBlurredBg={isRinging}
        modalContStyles={{
          ...blurBg,
          opacity: `1`,
        }}
        shouldMove={isEastablished}
        size={{ xs: `50%`, sm: `50%`, md: `40%`, lg: `30%`, xl: `30%` }}
        parentId="incomingVoiceCall"
        shouldOpen={openModal}
        title={paraTitle}
      >

        {listOfCalls
          ?.filter((call) => call?.shouldDisplay)
          ?.map((call) => {
            return (
              <Box key={call?.from}>
                {` `}
                <Box sx={{ fontWeight: 600, p: 1 }}>
                  <CallTimer isEastablished={isEastablished} />
                </Box>
                <Box>
                  <Stack spacing={2} direction={`row`} justifyContent={`center`}>
                    <ConditionallyRenderWrapper con={isRinging}>
                      <Box>
                        <Button
                          disabled={isAcceptingCall}
                          {...buttonProps({
                            bgCol: `green`,
                            hovBg: `darkgreen`,
                            onClick: () => timeLogProt(call),
                          })}
                        >
                          <AcceptCallSuspenser isAcceptingCall={isAcceptingCall} />
                        </Button>
                      </Box>

                      <Box>
                        <Button
                          disabled={isAcceptingCall}
                          {...buttonProps({
                            onClick: () => {
                              newSocket.emit(`incommingVoiceCall`, { practiceId, status: `sendToVoiceMail`, userId: loggedUserId, from: call?.from });
                              resetState({
                                patientId: call?.from,
                                from: `vM`,
                              });
                            },
                            ...sendVmbtn,
                          })}
                        >
                          {` `}
                          Send to voice mail
                        </Button>
                      </Box>
                    </ConditionallyRenderWrapper>
                    <ConditionallyRenderWrapper con={isEastablished}>
                      <ToolTipProvider
                        element={
                          <Box>
                            <IconButton
                              size="small"
                              variant="contained"
                              sx={{
                                backgroundColor: defFontColor,

                                color: `white`,
                                '&:hover': {
                                  backgroundColor: defFontColor,
                                  color: `white`,
                                },
                              }}
                              onClick={() => {
                                connection.mute(!isMuted);
                                setIsMuted((p) => !p);
                              }}
                            >
                              {isMuted ? <MicOffIcon /> : <KeyboardVoiceIcon />}
                            </IconButton>
                          </Box>
                        }
                        toolTipProps={{
                          title: isMuted ? `Unmute` : `Mute`,
                          PopperProps: {
                            style: { zIndex: `100000` },
                          },
                        }}
                      />
                    </ConditionallyRenderWrapper>
                    <ConditionallyRenderWrapper con={listOfCalls?.length}>
                      <Box>
                        <Button
                          disabled={isAcceptingCall}
                          {...buttonProps({
                            onClick: () => endCall(isRinging, false, call?.from),
                            ...endOrIgnbtn,
                          })}
                        >
                          {isEastablished || call?.status === `answered` ? `End call` : `Ignore`}
                        </Button>
                      </Box>
                    </ConditionallyRenderWrapper>
                    <ConditionallyRenderWrapper con={listOfCalls?.length > 1 && !isAcceptingCall}>
                      <MoreIncomingCalls
                        practiceId={practiceId}
                        setIsAcceptingCall={setIsAcceptingCall}
                        loggedUserId={loggedUserId}
                        setListOfCalls={setListOfCalls}
                        listOfCalls={listOfCalls}
                        acceptCall={(call) => timeLogProt(call)}
                      />
                    </ConditionallyRenderWrapper>
                  </Stack>
                </Box>
              </Box>
            );
          })}
      </ReactModalWrapper>
    </SocketConnDisConProtection>
  );
};

const CallTimer = ({ isEastablished }) => {
  const callTime = useRef(0);
  const callTimer = useRef(0);
  useEffect(() => {
    const changeTime = (seconds) => {
      let time = formatDurationHms(seconds);
      if (callTimer.current) callTimer.current.innerHTML = time;
    };

    if (isEastablished) {
      callInterval = setInterval(() => {
        callTime.current = callTime.current + 1;
        changeTime(callTime.current);
      }, 1000);
    }
    return () => {
      if (callInterval) clearInterval(callInterval);
      callTime.current = 0;
    };
  }, [isEastablished]);
  return <Box ref={callTimer} sx={{ fontWeight: 600, textAlign: `center` }}></Box>;
};

const MoreIncomingCalls = ({ acceptCall, loggedUserId, listOfCalls, setListOfCalls, practiceId }) => {
  const [openCalls, setOpenCalls] = useState(false);
  const [anchorElem, setAnchorElem] = useState(null);
  let filteredCalls = (shouldFilterDisplaying) =>
    listOfCalls?.filter((call) => {
      if (shouldFilterDisplaying) {
        return call?.shouldDisplay;
      }
      return !call?.shouldDisplay;
    });

  let size = {
    size: `small`,
  };
  const resetState = () => {
    setOpenCalls(false);
    setAnchorElem(null);
  };

  useEffect(() => {
    return () => resetState();
  }, []);

  const removeFromCalls = (call, calls) => {
    if (!calls || !Array.isArray(calls)) {
      return calls;
    }

    return calls.filter((callInner) => {
      return call && callInner && call.from !== callInner.from;
    });
  };

  const acceptCallHandler = (call) => acceptCall(call);

  return (
    <Box>
      <BlinkingAnimationWrapper>
        <MuiBtn
          exctProps={{
            sx: { fontWeight: `bold` },
            endIcon: <ArrowDropDownCircleIcon />,
            variant: `contained`,
            onClick: (e) => {
              setOpenCalls((p) => !p);
              setAnchorElem(e.currentTarget);
            },
          }}
        >
          More...
        </MuiBtn>
      </BlinkingAnimationWrapper>

      <MuiPopOver
        PaperProps={{
          sx: {
            maxHeight: `200px`,
            ...blurBg,
            opacity: `.9`,
          },
        }}
        sx={{
          zIndex: 10000000,
        }}
        open={openCalls}
        onClose={() => {
          setOpenCalls((p) => !p);
          setAnchorElem(null);
        }}
        anchorEl={anchorElem}
      >
        {filteredCalls(false)?.map((call) => (
          <MenuItem key={call?.from} sx={{ p: 2, pb: 1 }}>
            <Box>
              <Box sx={{ fontWeight: 600 }}>Incoming call from {`${capitalizeFirstLetter(`${call?.firstName} ${call?.lastName}`)}`}</Box>
              <Stack direction="row" spacing={1}>
                <Box>
                  <Button
                    {...size}
                    {...buttonProps({
                      ...accptBtn,
                      onClick: () => acceptCallHandler(call),
                    })}
                  >
                    Accept
                  </Button>
                </Box>
                <Box>
                  <Button
                    {...size}
                    {...buttonProps({
                      ...sendVmbtn,
                      onClick: () => {
                        newSocket.emit(`incommingVoiceCall`, { practiceId, status: `sendToVoiceMail`, userId: loggedUserId, from: call?.from });
                        setListOfCalls((calls) => [...removeFromCalls(call, calls)]);
                      },
                    })}
                  >
                    Send to voice mail
                  </Button>{` `}
                </Box>
                <Box>
                  {` `}
                  <Button
                    {...size}
                    {...buttonProps({
                      ...endOrIgnbtn,
                      onClick: () => setListOfCalls((calls) => [...removeFromCalls(call, calls)]),
                    })}
                  >
                    Ignore
                  </Button>{` `}
                </Box>
              </Stack>
            </Box>
          </MenuItem>
        ))}
      </MuiPopOver>
    </Box>
  );
};

const SocketConnDisConProtection = ({ children, setListOfCalls, resetState }) => {
  const { user: { socketConn: connected = false } } = useSelector((state) => state)

  useEffect(() => {
    if (!connected) {
      setListOfCalls((calls) => {
        if (calls?.length) {
          const isCallGoing = calls?.find((calls) => calls?.status === `answered`)
          if (!isCallGoing) {
            errorToast(`Unable to answer the calls for bad network connection`)
            resetState({ from: `socetDisconnectivity` })
          }
          return isCallGoing ? [{ ...isCallGoing }] : []
        }
        return []
      })
    }
  }, [connected])
  return children
}
const FetchNewTwilAuth = ({ children }) => {
  const { user: { twillioVoiceAuth } } = useSelector((state) => state)
  useEffect(() => {
    twilioDevice.setup(twillioVoiceAuth);
  }, [twillioVoiceAuth])
  return children
}
export const IncomingVoiceCallWrapper = ({ children }) => {
  const {
    user: { user: loggedInUser, twillioVoiceAuth },
  } = useSelector((state) => state);
  return (
    <>
      {children}
      <ConditionallyRenderWrapper con={(loggedInUser?.isProvider || loggedInUser?.isClinicalStaff) && twillioVoiceAuth}>
        <FetchNewTwilAuth>
          <IncomingVoiceCall />
        </FetchNewTwilAuth>
      </ConditionallyRenderWrapper>
    </>
  );
};