import React, { useEffect, useState } from 'react';
import css from './eFax.module.css';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { eFaxLogs, eFaxReSend, getRequest, postRequest } from 'src/crud/crud';
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp';
import moment from 'moment';
import { MuiPill } from 'src/pages/common/components/BadgePill/BadgePill';
import { faxStatusPill } from 'src/utils';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { sortIconHandler, sortKeyGiver } from '../patient2/PatientOverview/PatientOverView';
import { TablePagination } from 'src/pages/home/modules/multiReassignUsersAndPatients/forUserTablePagination';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { eFaxReports } from 'src/crud/crud';
import { PastDataPDFViewer } from '../patient2/components/patientDetails/moreDetails/pastData/pastDataPDFviewer/PastDataPDFViewer';

export const EFaxTable = () => {
  const [listOfLogs, setListOfLogs] = useState([]);
  const [loadingState, setLoading] = useState({ error: false, loading: false });
  const { loading } = loadingState;
  const [sortKey, setSortKey] = useState(`faxDate`);
  const [sortOrder, setSortOrder] = useState(`ASC`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [fileURL, setFileURL] = useState(``);
  const [show, setShow] = useState(false);

  const loadingSetter = (key, value) => {
    setLoading((p) => ({ ...p, [key]: value }));
  };

  const sortKeyDecider = sortOrder === `ASC` ? -1 : 1;

  let query = eFaxLogs + `?pageNumber=${currentPage}&pageSize=${rowsPerPage}&sortField=${sortKey}&sortOrder=${sortKeyDecider}`;

  const getListOfFax = () => {
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    getRequest(query)
      .then((response) => {
        setListOfLogs(response?.data?.logs);
        setTotalRows(response?.data?.totalRecords);
        if (Math.ceil(response?.data?.totalRecords / response?.data?.recordsPerPage)) {
          setTotalPage(Math.ceil(response?.data?.totalRecords / response?.data?.recordsPerPage));
        }
        loadingSetter(`loading`, false);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  useEffect(() => {
    getListOfFax();
  }, [sortKey, sortOrder, rowsPerPage, currentPage]);

  const handleResendEFax = (selectedItem) => {
    let payload = {
      faxId: selectedItem._id,
    };
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    postRequest(eFaxReSend, payload)
      .then((response) => {
        loadingSetter(`loading`, false);
        successToast(response.data.message);

        if (response.data.data._id === selectedItem._id) {
          const updatedLogs = listOfLogs?.map((log) => (log._id === selectedItem._id ? { ...log, status: response.data.data.status } : log));
          setListOfLogs(updatedLogs);
        }
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  const sortHandler = (order, key) => {
    setSortKey(key);
    setSortOrder(order);
  };

  const handleEFaxReports = (selectedItem) => {
    let temp = null;
    let payload = {
      faxId: selectedItem._id,
    };
    let query = eFaxReports + `/${payload.faxId}`;
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    getRequest(query, { responseType: `arraybuffer` })
      .then((response) => {
        loadingSetter(`loading`, false);
        temp = new Blob([response.data], { type: `application/pdf` });
        setFileURL(URL.createObjectURL(temp));
        setShow(true);
      })
      .catch(() => {
        setShow(false);
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  useEffect(() => {
    if (newSocket) {
      newSocket.on('faxStatusUpdated', (data) => {
        setListOfLogs((prevLogs) => {
          const updatedLogs = prevLogs.map((log) => {
            // Compare the IDs and update the specific log that matches the socket data
            if (log._id === data._id) {
              return { ...log, status: data.status };
            }
            return log;
          });
          return updatedLogs;
        });
      });
    }

    // Clean up the socket listener when the component unmounts.
    return () => {
      if (newSocket) {
        newSocket.off('faxStatusUpdated');
      }
    };
  }, [newSocket]);

  const columnNames = [
    { key: 'patientName', name: 'Patient Name' },
    { key: 'reportMonth', name: 'Report Month' },
    { key: 'providerName', name: 'Sent By' },
    { key: 'faxStatus', name: 'Status' },
    { key: 'faxDate', name: 'Date' },
    { key: 'action', name: 'Action' },
    { key: 'eFaxReports', name: 'Reports' },
  ];

  // Define a array of CSS classes for columns.
  const columnClasses = [css.customCol2, css.customCol3, css.customCol4, css.customCol4, css.customCol4, css.customCol4];

  let passArgs = { sortOrder: sortOrder, sortField: sortKey };

  const renderTableHeaders = () => {
    return columnNames.map((column, index) => (
      <th
        className={columnClasses[index]}
        key={column.key}
        scope="col"
        onClick={() =>
          column.key !== 'action' && column.key !== 'eFaxReports' ? sortHandler(sortKeyGiver({ ...passArgs }, column.key), column.key) : null
        }
      >
        {column.name}
        {column.key !== 'action' && column.key !== 'eFaxReports' ? sortIconHandler({ ...passArgs }, column.key) : null}
      </th>
    ));
  };

  const formatDate = (date) => moment(date).format('MM/DD/YYYY');

  // Extract inline function for rendering log rows
  const renderLogRows = () => {
    return listOfLogs.length ? (
      listOfLogs.map((item, index) => {
        console.log(`item.status`, item.status);
        const parsedDate = moment(item?._reportId?.month, 'YYYYMM');
        const formattedDate = parsedDate.format('YYYY-MMMM');
        const formateDate = formatDate(item.createdAt);

        return (
          <tr key={index}>
            <td>
              <CapitalizeFirstLetterComp>{`${item?._patientId?.firstName} ${item?._patientId?.lastName}`}</CapitalizeFirstLetterComp>
            </td>
            <td>{formattedDate}</td>
            <td>
              {item.source === `USER` ? (
                <CapitalizeFirstLetterComp>{`${item?._userId?.firstName} ${item?._userId?.lastName}`}</CapitalizeFirstLetterComp>
              ) : (
                `AUTO`
              )}
            </td>
            <td>
              <MuiPill
                pillProps={{
                  sx: {
                    backgroundColor: faxStatusPill[item.status] ? faxStatusPill[item.status] : 'red',
                    color: `white`,
                    borderRadius: `none`,
                    fontSize: `10px`,
                  },
                  size: `small`,
                }}
              >
                <CapitalizeFirstLetterComp>{item.status}</CapitalizeFirstLetterComp>
              </MuiPill>
            </td>
            <td>{formateDate}</td>
            <td>
              <button
                id={css.headButton}
                className="btn btn-info h-25"
                onClick={() => {
                  handleResendEFax(item);
                }}
              >
                Resend
              </button>
            </td>
            <td>
              <button
                id={css.headButton}
                className="btn btn-info h-25"
                onClick={() => {
                  handleEFaxReports(item);
                }}
                disabled={item.status === `sending` ? true : false}
              >
                View
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="8" style={{ textAlign: `center` }}>
          No Data found!
        </td>
      </tr>
    );
  };

  return (
    <>
      <div id={css.eFaxContainer}>
        <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
          <table className="table table-striped">
            <thead style={{ borderBottom: `1px solid grey` }}>
              <tr style={{ backgroundColor: `white` }}>
                <td id={css.headTextContainer} colSpan={4}>
                  <h4 id={css.headText}>E Fax Logs</h4>
                </td>
              </tr>
              <tr>{renderTableHeaders()}</tr>
            </thead>
            <tbody id={css.body}>{renderLogRows()}</tbody>
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              setRowsPerPage={setRowsPerPage}
              totalPage={totalPage}
              isPatientView={false}
              hasStyle={true}
              data={listOfLogs}
            />
          </table>
        </div>
      </div>

      {loading && <PulseSpinner />}

      {show && <PastDataPDFViewer show={show} setShow={setShow} fileURL={fileURL} />}
    </>
  );
};
