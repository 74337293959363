import React from 'react';
import { Patient } from 'src/pages/home/modules/patient2/patient';
import { SmartPhrase } from 'src/pages/home/modules/administration/smartPhrases/smartphrase';
import { Ticketing } from 'src/pages/common/components/wrapper/components/help/ticketing/Ticketing';
import TaskManagement from 'src/pages/home/modules/TaskManagement/TaskManagement';
import { Messenger } from 'src/pages/home/modules/messenger/messenger';
import { Faqs } from 'src/pages/common/components/wrapper/components/help/faqs/Faqs';
import { Resources } from 'src/pages/common/components/wrapper/components/help/resources/resources';
import {
  billerRole,
  clinicalRole,
  clinicalRolePlusSuper,
  groupAdminRole,
  providerRole,
  providerRolePlusSuper,
  superAdminRole,
  systemAdminRole,
  clinicalRoles,
  providerRoles,
} from 'src/utils';
import { AnalyticsSuperAdmin } from 'src/pages/home/modules/analytics/analytics';
import { SettingsSystemAdmin } from 'src/pages/home/modules/settings/settings';
import { ListView } from 'src/pages/common/components/listview/ListView';
import { AnalyticsSystemAdmin } from 'src/pages/home/modules/settings/Analytics/Analytics';
import { BillingReportData } from 'src/pages/home/modules/administration/user/components/BillingReportData/BillingReportData';
import { GroupAdminUsers } from 'src/pages/home/dashboard/commonDashboard/components/Group admin/GroupAdminusers';
import { AdministrationPayment } from 'src/pages/home/modules/administration/payment';
import { PatientOverView } from 'src/pages/home/modules/patient2/PatientOverview/PatientOverView';
import { Telemedicine } from 'src/pages/home/modules/telemedicine/telemedicine';
import { AdministrationPractice } from 'src/pages/home/modules/administration/practice/practice';
import { AdministrationUser } from 'src/pages/home/modules/administration/user/user';
import { SystemAdminCredentials } from 'src/pages/home/modules/reactHealthCredentials/forSystemAdminCredentials';
import { ListAllPracticeWithCount } from 'src/pages/home/dashboard/commonDashboard/components/Systemadmin/ListAllPracticeWithCount';
import { Logout } from 'src/pages/auth/logout/logout';
import { Login } from 'src/pages/auth/login/login';
import { ForgetPassword } from 'src/pages/auth/forgetPassword/forgetPassword';
import { ResetPassword } from 'src/pages/auth/resetPassword/resetPassword';
import { NintyDaysResetPassword } from 'src/pages/auth/resetPassword/nintyDaysResetPassword';
import { VerifyEmail } from 'src/pages/auth/verify/verify';
import Signup from 'src/pages/auth/patient/signup';
import PatientForget from 'src/pages/auth/patientForgetPassword/patientForget';
import { Otp } from 'src/pages/auth/otp/otp';
import { VideoPublicClient } from 'src/pages/common/videoPublicClient/videoPublicClient';
import { SubscriptionError } from 'src/pages/subscriptionError';
import { UserPatientReassignment } from 'src/pages/home/modules/multiReassignUsersAndPatients/usersAndPatientsReassignment';
import { PatientBulkImport } from 'src/pages/home/modules/patient2/patientBulkImportSelection';
import { EFax } from 'src/pages/home/modules/eFax/eFax';

const allRoles = [billerRole, clinicalRole, clinicalRolePlusSuper, providerRole, providerRolePlusSuper, systemAdminRole, groupAdminRole, superAdminRole];

export const privateRoutes = [
  { path: `/subscription-error`, comp: SubscriptionError, auth: [providerRole, clinicalRole, billerRole], shouldProtectWithStripe: true },
  { path: `/patient`, comp: Patient, auth: [billerRole, ...clinicalRoles, ...providerRoles] },
  { path: `/telemedicine`, comp: Telemedicine, auth: [...providerRoles] },
  { path: `/patients/overview`, comp: PatientOverView, auth: [...providerRoles] },
  {
    path: `/smart/phrases`,
    comp: SmartPhrase,
    auth: [...allRoles],
  },
  { path: `/ticketing`, comp: Ticketing, auth: [...allRoles] },
  { path: `/taskmanagement`, comp: TaskManagement, auth: [billerRole, ...clinicalRoles, ...providerRoles, superAdminRole] },
  { path: `/messenger`, comp: Messenger, auth: [billerRole, superAdminRole, ...clinicalRoles, ...providerRoles] },
  { path: `/faqs`, comp: Faqs, auth: [...allRoles] },
  { path: `/resources`, comp: Resources, auth: [...allRoles] },
  { path: `/analytics`, comp: AnalyticsSuperAdmin, auth: [...clinicalRoles, ...providerRoles, superAdminRole] },
  { path: `/patients/bulkimport`, comp: PatientBulkImport, auth: [...clinicalRoles, ...providerRoles, superAdminRole] },
  { path: `/analytics`, comp: AnalyticsSystemAdmin, auth: [groupAdminRole, systemAdminRole] },
  { path: `/settings`, comp: SettingsSystemAdmin, auth: [...clinicalRoles, ...providerRoles, groupAdminRole, systemAdminRole] },
  { path: `/SystemAdmin/Group/Practices`, comp: ListAllPracticeWithCount, auth: [systemAdminRole] },
  { path: `/systemAdmin/administration/user`, comp: AdministrationUser, auth: [systemAdminRole] },
  { path: `/list/view/:id?`, comp: ListView, auth: [systemAdminRole, groupAdminRole] },
  { path: `/GroupAdmin/user/billingReports/:id`, comp: BillingReportData, auth: [groupAdminRole] },
  { path: `/systemAdmin/administration/user/billingReports/:id`, comp: BillingReportData, auth: [systemAdminRole] },
  { path: `/groupAdmin/administration/user`, comp: GroupAdminUsers, auth: [groupAdminRole] },
  { path: `/admin/payments`, comp: AdministrationPayment, auth: [groupAdminRole, systemAdminRole] },
  { path: `/administration/practice`, comp: AdministrationPractice, auth: [providerRolePlusSuper, clinicalRolePlusSuper, superAdminRole] },
  { path: `/administration/user`, comp: AdministrationUser, auth: [providerRolePlusSuper, clinicalRolePlusSuper, superAdminRole] },
  { path: `/administration/payment`, comp: AdministrationPayment, auth: [providerRolePlusSuper, clinicalRolePlusSuper, superAdminRole] },
  { path: `/credentials`, comp: SystemAdminCredentials, auth: [systemAdminRole] },
  { path: `/administration/patients`, comp: UserPatientReassignment, auth: [providerRolePlusSuper, clinicalRolePlusSuper, superAdminRole] },
  { path: `/eFax`, comp: EFax, auth: [providerRolePlusSuper, clinicalRolePlusSuper, superAdminRole, providerRole] },
];

export const publicRoutes = [
  { path: `/logout`, comp: Logout },
  { path: `/login`, comp: Login },
  { path: `/forget`, comp: ForgetPassword },
  { path: `/resetPassword/:secret/:id`, comp: ResetPassword, spreadProps: true },
  { path: `/ninetyDaysResetPassword/:secret/:id`, comp: NintyDaysResetPassword, spreadProps: true },
  { path: `/users/verify/:secret/:id/:_practiceId/:isExisting`, comp: VerifyEmail, spreadProps: true },
  { path: `/patient/join/:id`, comp: Signup },
  { path: `/patient/reset/:secret/:id`, comp: PatientForget },
  { path: `/otp`, comp: Otp },
  { path: `/public/videoCall/:token`, comp: VideoPublicClient, spreadProps: true },
  { path: `/telemedicine/public/videoCall/:token`, comp: VideoPublicClient, spreadProps: true },
];
