import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, MenuItem, ListSubheader, Grid, IconButton } from '@mui/material';
import css from './basicDetails.module.css';
import Chip from '@mui/material/Chip';
import { resmedGroupedOptions, respironicsGroupedOptions, fisherAndPaykelGroupedOptions } from '../../importPatient/data';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import { toast } from 'react-toastify';
import { handleSelectedTab, updatePatientMask } from 'src/redux/patient/patientActions';
import { capitalizeFirstLetter, isDme, saveInLs } from 'src/utils';
import {
  PATIENT_WITH_OUT_DEVICE_DME_URL,
  PATIENT_UPDATE_URL,
  patchRequest,
  UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS,
} from '../../../../../../../crud/crud';
import { momentWrapper } from 'src/momentWrapper';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { TimeLogs } from '../../timeLogs/timeLogs';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { EditPatient } from '../editPatient/editPatient';
import Swal from 'sweetalert2';
import { DateFormat } from 'src/pages/common/components/DateFormat/DateFormat';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { SaveNotes } from './SaveNotes';
import { MultipleUsersLogs } from '../../multilpleTimeLogs/MultipleUsersLogs';
import { store } from 'src/redux/store';
import moment from 'moment';
import { Timer } from './Timer';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';
import { ViewUsersList } from './ViewUsersList';
import { useLocation } from 'react-router-dom';

export const PatientBasicDetails = () => {
  const userId = useSelector((state) => state?.patient?.patientData?.data?._id);
  const { user } = useSelector((state) => state.user);
  const timer = useSelector((state) => state.patient.timer);
  const selectedTab = useSelector((state) => state.patient.selectedTab);
  const patientData = useSelector((state) => state.patient.patientData);
  const [notes, setNotes] = useState(``);
  const [notesUpdatingFlag, setNotesUpdatingFlag] = useState(`none`);
  const [renderCount, setRenderCount] = useState(0);
  const [visibleFlag, setVisibleFlag] = useState(true);
  // changing
  // mask values
  const [maskValues, setMaskValues] = useState({ manufacturer: ``, type: ``, size: `` });
  ///mask refs
  const maskManufacturerRef = useRef(null);
  const maskTypeRef = useRef(null);
  const maskSizeRef = useRef(null);
  // mask Errors
  const [maskErrors, setMaskErrors] = useState({ manufacturer: ``, size: ``, type: `` });
  const [maskModal, setMaskModal] = useState(false);
  const [timeLogModal, setTimeLogModal] = useState(false);
  const [editPatientModal, setEditPatientModal] = useState(false);
  //
  const dispatchRedux = useDispatch();
  const selectedPatient = useSelector((state) => state?.patient?.patientReport);
  //
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);
  const location = useLocation();

  useEffect(() => {
    if (userId && !user?.isBiller) {
      patchRequest(UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS + userId, {
        ...(location.updateNotif ? { ...location.updateNotif } : { allMissedSMS: true }),
      })
        .then(() => { })
        .catch(() => null);
    }
  }, [timer.toggleButton, userId, location]);

  // To set Masks
  useEffect(() => {
    if (patientData?.data?.mask) {
      setMaskValues(patientData?.data?.mask);
    } else {
      setMaskValues({});
    }
  }, [patientData]);

  useEffect(() => {
    setNotes(patientData?.data?.notes ? patientData?.data?.notes : ``);
    return () => setNotesUpdatingFlag(`none`);
  }, [patientData?.data?.notes]);

  useEffect(() => {
    window.addEventListener(`unload`, () => {
      const { timer, patientData } = store?.getState()?.patient || {};
      const { data } = patientData || {};
      const { _id: patientId } = data || false;
      const { toggleButton } = timer;
      if (toggleButton) {
        let endTimeValue = momentWrapper().toISOString();
        const preparePayLoad = {
          toggleButton: true,
          patientInfo: { _id: patientId },
          timeLogInfo: {
            startDate: timer?.startTime,
            callSuccess: false,
            type: `Auto`,
            source: `View Report`,
            startOfMonth: momentWrapper().startOf(`month`).toISOString(),
            description: timer?.logDescription,
            endDate: endTimeValue,
            now: momentWrapper().toISOString(),
          },
        };
        saveInLs(`pageRefreshState`, { ...preparePayLoad });
      }
    });
    return () => {
      dispatchRedux({ type: `MSGAPICALL`, payLoad: { loading: false, error: false } });
    };
  }, []);

  const autoUpdateNotes = () => {
    let url = isDme() ? PATIENT_WITH_OUT_DEVICE_DME_URL + userId : PATIENT_UPDATE_URL + userId;
    setNotesUpdatingFlag(`updating`);
    patchRequest(url, {
      notes,
    })
      .then(() => {
        setNotesUpdatingFlag(`done`);
      })
      .catch(() => {
        setNotesUpdatingFlag(`error`);
      });
  };

  const radioButtonMaskTypeHandler = async () => {
    try {
      let isValid = await validateMask();
      if (isValid) {
        Swal.fire({
          title: `Are you sure?`,
          text: `You want to change the mask type!`,
          icon: `warning`,
          showCancelButton: true,
          confirmButtonColor: `#1e85e7a6`,
          cancelButtonColor: `#f12e2eb8`,
          confirmButtonText: `Yes, change it!`,
        }).then((result) => {
          let url = isDme() ? PATIENT_WITH_OUT_DEVICE_DME_URL + userId : PATIENT_UPDATE_URL + userId;
          if (result.isConfirmed) {
            patchRequest(url, {
              mask: maskValues,
            })
              .then((res) => {
                setMaskModal(false);
                dispatchRedux(updatePatientMask(res?.data?.updatePatient));
                Swal.fire(`Success!`, `Mask type changed`, `success`);
              })
              .catch(() => {
                // errorToast(`Something bad happened try again later`);
              });
          } else {
            Swal.fire(`Changes are not saved`);
          }
        });
      } else {
        toast.error(`Something bad happened please try again later`);
      }
    } catch (e) {
      toast.error(`Something bad happened please try again later`);
    }
  };
  //changing

  const validateMask = async (field) => {
    switch (field) {
      case `manufacturer`:
        if (!maskValues?.manufacturer) {
          setMaskErrors({ size: ``, manufacturer: ``, type: `` });
          return true;
        } else if (maskValues?.manufacturer && !maskValues.type) {
          setMaskErrors({ size: `*Required`, type: `*Required` });
          return false;
        } else if (maskValues?.manufacturer && !maskValues?.size) {
          setMaskErrors({ size: `*Required`, type: `*Required` });
          return false;
        } else if (maskValues?.manufacturer && maskValues?.size && maskValues.type) {
          setMaskErrors({ size: ``, manufacturer: ``, type: `` });
          return true;
        }
        break;
      case `type`:
        if (maskValues.type) {
          setMaskErrors({ ...maskErrors, type: `` });
          return true;
        } else if (!maskValues.type) {
          setMaskErrors({ ...maskErrors, type: `*Required` });
          return false;
        }
        break;
      case `size`:
        if (maskValues?.size) {
          setMaskErrors({ ...maskErrors, size: `` });
          return true;
        } else if (!maskValues?.size) {
          setMaskErrors({ ...maskErrors, size: `*Required` });
          return false;
        }
        break;
      default:
        if (!maskValues?.manufacturer) {
          setMaskErrors({ size: ``, manufacturer: ``, type: `` });
          return true;
        } else if (maskValues?.manufacturer && !maskValues.type) {
          setMaskErrors({ size: `*Required`, type: `*Required` });
          return false;
        } else if (maskValues?.manufacturer && !maskValues?.size) {
          setMaskErrors({ size: `*Required`, type: `*Required` });
          return false;
        } else if (maskValues?.manufacturer && maskValues?.size && maskValues.type) {
          setMaskErrors({ size: ``, manufacturer: ``, type: `` });
          return true;
        }
        if (maskValues.type) {
          return true;
        } else if (!maskValues.type) {
          setMaskErrors({ ...maskErrors, type: `*Required` });
          return false;
        }
        if (maskValues?.size) {
          setMaskErrors({ ...maskErrors, size: `` });
          return true;
        } else if (!maskValues?.size) {
          setMaskErrors({ ...maskErrors, size: `*Required` });
          return false;
        } else {
          return true;
        }
    }
  };
  // onChange for maskModal to edit...
  const onChangeForMask = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === `manufacturer`) {
      setMaskValues({ manufacturer: value, type: ``, size: `` });
    } else if (name === `type`) {
      const copyMaskValues = { ...maskValues };
      copyMaskValues[name] = value;
      setMaskValues(copyMaskValues);
    } else if (name === `size`) {
      const copyMaskValues = { ...maskValues };
      copyMaskValues[name] = value;
      setMaskValues(copyMaskValues);
    }
  };

  // changing
  // mask sizeOptions

  const resmedSize = [
    {
      value: `Standard`,
      label: `Standard`,
    },
    {
      value: `Wide`,
      label: `Wide`,
    },
    {
      value: `Small`,
      label: `Small`,
    },
    {
      value: `Medium`,
      label: `Medium`,
    },
    {
      value: `Large`,
      label: `Large`,
    },
    {
      value: `Small Wide`,
      label: `Small Wide`,
    },
    {
      value: `X-Small`,
      label: `X-Small`,
    },
  ];

  const respironicsSize = [
    {
      value: `Petite`,
      label: `Petite`,
    },
    {
      value: `X-Small`,
      label: `X-Small`,
    },
    {
      value: `Small`,
      label: `Small`,
    },
    {
      value: `Medium`,
      label: `Medium`,
    },
    {
      value: `Large`,
      label: `Large`,
    },
    {
      value: `Medium Wide`,
      label: `Medium Wide`,
    },
    {
      value: `wide`,
      label: `wide`,
    },
  ];

  const fisherSize = [
    {
      value: `XS/S`,
      label: `XS/S`,
    },
    {
      value: `M/L`,
      label: `M/L`,
    },
    {
      value: `Small`,
      label: `Small`,
    },
    {
      value: `Medium`,
      label: `Medium`,
    },
    {
      value: `Large`,
      label: `Large`,
    },
  ];

  let error = Object.values(maskErrors);

  let hasError = error?.length > 0 && error.every((e) => e.length > 0);

  const onMaskModalUnmount = () => {
    setMaskValues(patientData?.data?.mask);
    setMaskModal(false);
    setMaskErrors({});
  };

  let sets = [];
  for (let key in patientData?.data?.device?.set) {
    sets.push({ key, data: patientData?.data?.device?.set[key] });
  }

  const latestSessionDate = patientData?.data?.latestSessionDate;
  const formattedLatestSessionDate = latestSessionDate !== null ? momentWrapper(latestSessionDate).format(`MM/DD/YYYY h:mm A`) : ``;

  const keysToInclude = [
    `Mode`,
    `min_apap`,
    `max_apap`,
    `mask_type`,
    `ramp_time`,
    `humidifier`,
    `reslex`,
    `reslex_patient`,
    `min_epap`,
    `min_ipap`,
    `max_ipap`,
    `rise_time`,
    `isens`,
    `esens`,
  ];

  let assignSettings = {};

  if (patientData?.data?.device?.settings) {
    assignSettings = patientData?.data?.device?.settings;
  }

  const filteredObject = {};
  for (const key of keysToInclude) {
    if (assignSettings.hasOwnProperty(key)) {
      filteredObject[key] = assignSettings[key];
    }
  }

  return (
    <div
      id="details"
      className="w-100 pt-3"
      style={{
        backgroundColor: `transparent`,
        position: `relative`,
      }}
    >
      <div className="d-flex justify-content-between">
        <div id={css.buttonNavBar} className="d-flex">
          {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller ? (
            <button
              className="btn ml-3"
              style={{ fontSize: `1vw` }}
              id={selectedTab === `viewData` ? css.customButtonSelected : css.customButton}
              onClick={() => dispatchRedux(handleSelectedTab(`viewData`))}
            >
              View Data
            </button>
          ) : null}
          <button
            disabled={!patientData?.data?._id}
            className="btn ml-3"
            style={{ fontSize: `1vw` }}
            id={selectedTab === `past` ? css.customButtonSelected : css.customButton}
            onClick={() => dispatchRedux(handleSelectedTab(`past`))}
          >
            Past Data
          </button>
        </div>
        {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller ? (
          <Timer setTimeLogModal={setTimeLogModal} setVisibleFlag={setVisibleFlag} visibleFlag={visibleFlag} />
        ) : null}
      </div>

      <div className="collapse show" id="patientBasicInfo">
        {patientData?.data ? (
          <div className="pt-3">
            <div className="row mb-2">
              <div className="col-8 d-flex">
                <h4
                  style={{
                    color: `black`,
                    fontSize: `large`,
                    fontWeight: `600`,
                    margin: `0 15px 0 0`,
                  }}
                  className="text-capitalize"
                >{`${capitalizeFirstLetter(patientData?.data?.lastName)}, ${capitalizeFirstLetter(patientData?.data?.firstName)}`}</h4>
                {user.isBiller ? null : (user.isSuperAdmin || user.isProvider || user.isClinicalStaff) && isOnVideo ? (
                  <IconButton
                    disabled={isOnVideo}
                    className="text-primary"
                    style={{ fontSize: `x-small`, cursor: `pointer` }}
                    onClick={() => {
                      setRenderCount(renderCount + 1);
                      setEditPatientModal(true);
                    }}
                  >
                    Edit Patient
                  </IconButton>
                ) : (
                  <p
                    className="text-primary"
                    style={{ fontSize: `x-small`, cursor: `pointer` }}
                    onClick={() => {
                      setRenderCount(renderCount + 1);
                      setEditPatientModal(true);
                    }}
                  >
                    Edit Patient
                  </p>
                )}
                {selectedPatient?.selectedPatientReport?.isSigned && !selectedPatient?.selectedPatientReport?.isAddendumSigned ? (
                  <Chip label="Signed" style={{ marginLeft: 3, backgroundColor: `#1699c5`, color: `#fff` }} size="small" />
                ) : null}
                {selectedPatient?.selectedPatientReport?.isSigned && selectedPatient?.selectedPatientReport?.isAddendumSigned ? (
                  <Chip label="Addendum" style={{ marginLeft: 3, backgroundColor: `#1699c5`, color: `#fff` }} size="small" />
                ) : null}
                {patientData?.data?.interactiveCommunication ? (
                  <MissedVideoCallIcon style={{ color: `#1699c5` }} />
                ) : patientData?.data?.currentMonth?.leftVoiceMail || patientData?.data?.leftVoiceMail ? (
                  <VoicemailIcon
                    style={{
                      color: `#1699c5`,
                      marginLeft: 4,
                      marginTop: 2,
                    }}
                  />
                ) : null}
                {patientData?.data?.status?.helperStatus === `inactive` ? (
                  <Chip label="Inactive" style={{ marginLeft: 3, backgroundColor: `red`, color: `#fff` }} size="small" />
                ) : patientData?.data?.status?.helperStatus === `autoReactivation` ? (
                  <Chip label="Auto Re-Activation:" style={{ marginLeft: 3, backgroundColor: `red`, color: `#fff` }} size="small" />
                ) : patientData?.data?.status?.helperStatus === `recurringReactivation` ? (
                  <Chip label="Recurring Reactivation" style={{ marginLeft: 3, backgroundColor: `#28A745`, color: `#fff` }} size="small" />
                ) : null}
              </div>

              {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller ? (
                <div className="col-4">{userId ? <MultipleUsersLogs /> : null}</div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-8">
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  DOB : <DateFormat date={patientData?.data?.dateOfBirth} /> (
                  {momentWrapper().diff(momentWrapper(patientData?.data?.dateOfBirth), `years`)} yo)
                </div>
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Phone : {patientData?.data?.phoneNumber === null ? `(000) 000-0000` : patientData?.data?.phoneNumber}
                </div>

                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Enrollment Date : {momentWrapper(patientData?.data?.createdAt).format(`MM/DD/YYYY`)}
                </div>

                {patientData?.data?.status?.lastDeactivatedAt ? (
                  <>
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      Last Inactivated On : {momentWrapper(patientData?.data?.status?.lastDeactivatedAt).format(`MM/DD/YYYY`)}
                    </div>
                  </>
                ) : null}

                {patientData?.data?.reactivateAt && patientData?.data?.status === `inactive` && (
                  <div
                    style={{
                      color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `600`,
                    }}
                  >
                    Date Of Re-activation : {momentWrapper(patientData?.data?.reactivateAt).format(`MM/DD/YYYY`)}
                  </div>
                )}

                {patientData?.data?.status?.helperStatus === `autoReactivation` ||
                  patientData?.data?.status?.helperStatus === `recurringReactivation` ? (
                  <>
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      {patientData?.data?.status?.nextActivationOn
                        ? `Next Reactivation Date : ${moment(patientData?.data?.status?.nextActivationOn).format(`MM/DD/YYYY`)}`
                        : null}
                    </div>
                  </>
                ) : null}

                {patientData?.data?.status?.helperStatus === `recurringReactivation` && (
                  <>
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      {patientData?.data?.status?.nextDeactivationOn
                        ? `Next Deactivation Date : ${momentWrapper(patientData?.data?.status?.nextDeactivationOn).format(`MM/DD/YYYY`)}`
                        : null}
                    </div>
                  </>
                )}

                <br />
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Primary Dx: <span style={{ fontWeight: `500` }}> {patientData?.data?.primaryDiagnosis} </span>
                </div>
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Secondary Dx: <span style={{ fontWeight: `500` }}> {patientData?.data?.secondaryDiagnosis}</span>
                </div>
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Insurance Company: <span style={{ fontWeight: `500` }}> {patientData?.data?.insuranceCompany} </span>
                </div>

                <br />
                {/* GETTING FIRST NAME AND LAST NAME FROM REDUX - PROVIDER */}
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Provider:{` `} {capitalizeFirstLetter(`${patientData?.data?.provider?.firstName} ${patientData?.data?.provider?.lastName}`)}{` `}
                  {`, ${patientData?.data?.provider?.providerType}`}
                </div>
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Clinical Staff:{` `}{` `}
                  {capitalizeFirstLetter(`${patientData?.data?.clinicalUser?.firstName} ${patientData?.data?.clinicalUser?.lastName}`)}{` `}
                  {`, ${patientData?.data?.clinicalUser?.title === undefined ? `` : patientData?.data?.clinicalUser?.title}`}
                </div>
                <ViewUsersList />
                {patientData?.data?.baseLineAHI && (
                  <>
                    <div
                      className="text-capitalize"
                      style={{
                        color: `black`,
                        fontSize: `small`,
                        fontWeight: `600`,
                        marginTop: `10px`,
                      }}
                    >
                      Baseline AHI: <span style={{ fontWeight: `500` }}>{patientData?.data?.baseLineAHI}</span>
                    </div>

                    {patientData?.data?.WIP && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                          marginTop: `10px`,
                        }}
                      >
                        Baseline AHI: <span style={{ fontWeight: `500` }}>{patientData?.data?.baseLineAHI}</span>
                      </div>
                    )}
                    {patientData?.data?.WIP && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        WIP Status: <span style={{ fontWeight: `500` }}>{patientData?.data?.WIP}</span>
                      </div>
                    )}

                    {patientData?.data?.brightreeID && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        WIP Status: <span style={{ fontWeight: `500` }}>{patientData?.data?.WIP}</span>
                      </div>
                    )}

                    {patientData?.data?.brightreeID && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        Brightree ID: <span style={{ fontWeight: `500` }}>{patientData?.data?.brightreeID}</span>
                      </div>
                    )}

                    {patientData?.data?.salesOrderID && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        Sales Order ID: <span style={{ fontWeight: `500` }}>{patientData?.data?.salesOrderID}</span>
                      </div>
                    )}

                    {patientData?.data?.followUpDate && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        WIP Follow Up Date:{` `}
                        <span style={{ fontWeight: `500` }}>{momentWrapper(patientData?.data?.followUpDate).format(`MM/DD/YYYY`)}</span>
                      </div>
                    )}

                    {patientData?.data?.MFL && (
                      <div
                        className="text-capitalize"
                        style={{
                          color: `black`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                      >
                        MFL (Preferred Equipment): <span style={{ fontWeight: `500` }}>{patientData?.data?.MFL}</span>
                      </div>
                    )}
                  </>
                )}
                {patientData?.data?.MRN && (
                  <div
                    className="text-capitalize"
                    style={{
                      color: `black`,
                      fontSize: `small`,
                      fontWeight: `600`,
                      marginTop: `10px`,
                    }}
                  >
                    MRN: <span style={{ fontWeight: `500` }}>{patientData?.data?.MRN}</span>
                  </div>
                )}
                <br />
                <ConditionallyRenderWrapper con={patientData?.data?.latestSessionDate}>
                  <div
                    style={{
                      // color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `600`,
                      color: `#000000`,
                      paddingTop: `20px`,
                    }}
                  >
                    Last Data Download: {`${formattedLatestSessionDate}`}
                  </div>
                </ConditionallyRenderWrapper>
                <br />
                <div className="d-flex justify-content-between">
                  <div
                    style={{
                      color: `black`,
                      fontSize: `small`,
                      fontWeight: `600`,
                      marginTop: `30px`,
                    }}
                  >
                    Risk:
                    {patientData?.data?.tier === `VERY HIGH RISK` ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(235, 47, 6)` }} aria-hidden="true" />
                    ) : patientData?.data?.tier === `HIGH RISK` ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(167, 9, 9)` }} aria-hidden="true" />
                    ) : patientData?.data?.tier === `MODERATE RISK` ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(34, 112, 147)` }} aria-hidden="true" />
                    ) : patientData?.data?.tier === `LOW RISK` ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(23, 162, 184)` }} aria-hidden="true" />
                    ) : (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(149, 175, 192)` }} aria-hidden="true" />
                    )}
                    {patientData?.data?.tier === `VERY HIGH RISK`
                      ? `Very High`
                      : patientData?.data?.tier === `HIGH RISK`
                        ? `High`
                        : patientData?.data?.tier === `MODERATE RISK`
                          ? `Medium`
                          : patientData?.data?.tier === `LOW RISK`
                            ? `Low`
                            : `No data > 30 days`}
                  </div>
                  <div
                    style={{
                      color: `black`,
                      fontSize: `small`,
                      fontWeight: `600`,
                      marginTop: `30px`,
                    }}
                  >
                    Adherence:
                    {patientData?.data?.isAdherent === true ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(23, 162, 184)` }} aria-hidden="true" />
                    ) : patientData?.data?.isAdherent === false ? (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(235, 47, 6)` }} aria-hidden="true" />
                    ) : (
                      <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(149, 175, 192)` }} aria-hidden="true" />
                    )}
                    {patientData?.data?.isAdherent === true
                      ? `Adherent`
                      : patientData?.data?.isAdherent === false
                        ? `Non-Adherent`
                        : `No data > 30 days`}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  {patientData?.data?.device?.manufacturer === `resmed`
                    ? `ResMed`
                    : patientData?.data?.device?.manufacturer === `reactHealth`
                      ? `Device Manufacture : React Health`
                      : patientData?.data?.device?.manufacturer === `respironics`
                        ? `Respironics`
                        : ``}
                </div>
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                    textTransform: `capitalize`,
                  }}
                >
                  {patientData?.data?.deviceOrgName}
                </div>
                {patientData?.data?.device?.manufacturer && (
                  <div
                    style={{
                      color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `600`,
                    }}
                  >
                    Setup date : {moment(patientData?.data?.setupDate).format(`MM/DD/YYYY`)}
                  </div>
                )}
                {filteredObject && patientData?.data?.device?.manufacturer === `reactHealth` && (
                  <div>
                    {Object?.keys(filteredObject)?.length === 0 ? (
                      null
                    ) : (
                      <>
                        {Object?.keys(filteredObject)?.map((key) => (
                          <div
                            key={key}
                            style={{
                              color: `#c1c1c1`,
                              fontSize: `small`,
                              fontWeight: `600`,
                            }}
                          >
                            {capitalizeFirstLetter(key)}: {filteredObject[key]}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
                {patientData?.data?.device?.manufacturer === `resmed` ? (
                  <div
                    style={{
                      color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `600`,
                    }}
                  >
                    Mode : {patientData?.data?.device?.mode !== null ? patientData?.data?.device?.mode : `N/A`}
                  </div>
                ) : patientData?.data?.device?.manufacturer === `reactHealth` ? null : (
                  patientData?.data?.device?.manufacturer && (
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      Type : {patientData?.data?.device?.type !== null ? patientData?.data?.device?.type : `N/A`}
                    </div>
                  )
                )}
                {patientData?.data?.device?.manufacturer === `resmed` ? (
                  <div
                    style={{
                      color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `600`,
                    }}
                  >
                    Description : {patientData?.data?.device?.detail !== null ? patientData?.data?.device?.detail : `N/A`}
                  </div>
                ) : patientData?.data?.device?.manufacturer === `reactHealth` ? null : (
                  patientData?.data?.device?.manufacturer && (
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      Source : {patientData?.data?.device?.dataSource !== null ? patientData?.data?.device?.dataSource : `N/A`}
                    </div>
                  )
                )}
                {/* {deviceSetting.map((item) => item)} * */}

                {sets.length > 0 &&
                  sets.map((set) => {
                    //
                    return (
                      <div
                        style={{
                          color: `#c1c1c1`,
                          fontSize: `small`,
                          fontWeight: `600`,
                        }}
                        key={set.key}
                      >
                        {set.key} : {set.data}
                      </div>
                    );
                  })}

                <div
                  className="my-2"
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Mask Info
                </div>
                <div
                  style={{
                    color: `#000`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  {patientData?.data?.mask?.type} {patientData?.data?.mask?.size}
                </div>
                {!user.isBiller && (
                  <div
                    onClick={() => setMaskModal(true)}
                    style={{
                      color: `#c1c1c1`,
                      fontSize: `small`,
                      fontWeight: `500`,
                      cursor: `pointer`,
                    }}
                  >
                    Click here to change mask info
                  </div>
                )}
              </div>
            </div>
            <SaveNotes notesFromRedux={patientData?.data?.notes} userId={userId} />
          </div>
        ) : (
          <div style={{ minHeight: `298px`, color: `#c1c1c1` }} className="d-flex justify-content-center align-items-center">
            Click patient from list to show its details.
          </div>
        )}
      </div>
      <EditPatient
        patientId={userId}
        editPatientModal={editPatientModal}
        setEditPatientModal={setEditPatientModal}
        renderCount={renderCount}
        set_edit_patient_rerender_flag={() => { }}
      />
      {patientData?.loading && <PuffSpinner />}
      <TimeLogs patientId={userId} setTimeLogModal={setTimeLogModal} timeLogModal={timeLogModal} />
      <CustomizedDialogs
        title="Mask Info"
        open={maskModal}
        setOpen={onMaskModalUnmount}
        size="sm"
        fullWidth={true}
        showCloseButton={true}
        customButton={true}
        customButtonText="Save"
        customButtonDisable={hasError}
        customButtonAction={() => radioButtonMaskTypeHandler()}
      >
        {/* changed the whole design  and functionality from here.. */}
        <Grid container rowSpacing={2} columnSpacing={1.5}>
          <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
            <TextField
              inputProps={{
                ref: maskManufacturerRef,
              }}
              fullWidth={true}
              select
              label="Mask Company"
              onBlur={() => validateMask(`manufacturer`)}
              // style={{ width: '100%', padding: '12px', borderRadius: '5px' }}
              onChange={(e) => onChangeForMask(e)}
              name="manufacturer"
              value={maskValues?.manufacturer}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="resmed">Resmed</MenuItem>
              <MenuItem value="respironics">Respironics</MenuItem>
              <MenuItem value="Fisher & Paykel">Fisher & Paykel</MenuItem>
            </TextField>
          </Grid>
          <Grid xs={12} sm={8} md={4} lg={4} xl={4} item>
            <TextField
              inputProps={{
                ref: maskTypeRef,
                value: maskValues?.type,
              }}
              error={maskErrors.type ? true : false}
              helperText={`${maskErrors.type ? maskErrors.type : ``}`}
              sx={{ width: `100%` }}
              label="Mask Type"
              onChange={(e) => onChangeForMask(e)}
              select
              name="type"
              onBlur={() => validateMask(`type`)}
            >
              {maskValues?.manufacturer === `resmed`
                ? resmedGroupedOptions.map((item, index) => [
                  <ListSubheader key={item.label + index}>{item.label}</ListSubheader>,
                  item.options.map((subItem, ind) => (
                    <MenuItem key={subItem.label + ind} value={subItem.value}>
                      {subItem.label}
                    </MenuItem>
                  )),
                ])
                : maskValues?.manufacturer === `respironics`
                  ? respironicsGroupedOptions.map((item, index) => [
                    <ListSubheader key={index + `respironics` + item.label}>{item.label}</ListSubheader>,
                    item.options.map((subItem, ind) => (
                      <MenuItem key={subItem.value + ind} value={subItem.value}>
                        {subItem.label}
                      </MenuItem>
                    )),
                  ])
                  : maskValues?.manufacturer === `Fisher & Paykel`
                    ? fisherAndPaykelGroupedOptions.map((item, index) => [
                      <ListSubheader key={index + `Fisher & Paykel` + item.label}>{item.label}</ListSubheader>,
                      item.options.map((subItem, ind) => (
                        <MenuItem key={ind + `Fisher & Paykel`} value={subItem.value}>
                          {subItem.label}
                        </MenuItem>
                      )),
                    ])
                    : null}
            </TextField>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={4} xl={4} item>
            <TextField
              error={maskErrors.size ? true : false}
              helperText={`${maskErrors.size ? maskErrors.size : ``}`}
              sx={{ width: `100%` }}
              select
              id="size"
              inputProps={{
                ref: maskSizeRef,
                value: maskValues?.size,
              }}
              onChange={(event) => onChangeForMask(event)}
              name="size"
              label="Mask Size"
              onBlur={() => validateMask(`size`)}
            >
              {maskValues?.manufacturer === `resmed`
                ? resmedSize.map((e) => {
                  return (
                    <MenuItem value={e.value} key={e.value}>
                      {e.label}
                    </MenuItem>
                  );
                })
                : maskValues?.manufacturer === `respironics`
                  ? respironicsSize.map((e) => {
                    return (
                      <MenuItem value={e.value} key={e.value}>
                        {e.label}
                      </MenuItem>
                    );
                  })
                  : maskValues?.manufacturer === `Fisher & Paykel`
                    ? fisherSize.map((e) => {
                      return (
                        <MenuItem value={e.value} key={e.value}>
                          {e.label}
                        </MenuItem>
                      );
                    })
                    : null}
            </TextField>
          </Grid>
        </Grid>
      </CustomizedDialogs>
    </div>
  );
};
