/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './commonDashboard.module.css';
import { StatsWidget } from './components/statsWidget/statsWidget';
import { MonitoringWidget } from './components/monitoringWidget/monitoringWidget';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MonitoringRiskWidget } from './components/riskWidget/riskWidget';
import { AdherenceWidget } from './components/adherenceWidget/adherenceWidget';
import { BillerRoleTable } from './components/BillerRoleTable/billerRoleTable';
import {
  getRequest,
  GET_REPORTS_BY_STATUS,
  patchRequest,
  UPDATE_REPORT_BILL_STATUS,
  UPDATE_ALL_REPORT_BILL_STATUS,
  postRequest,
  DASHBOARD_ANALYTICS,
  GET_PATIENT_ONLINE_OFFLINE_STATUS,
  BILLING_CSV_FILE,
  SMART_PHRASE_LIST,
} from '../../../../crud/crud';
import { useHistory } from 'react-router-dom';
import { AdministrationPractice } from '../../../home/modules/administration/practice/practice';
import { momentWrapper } from 'src/momentWrapper';
import Swal from 'sweetalert2';
import { capitalizeFirstLetter, isDme, printInBrowser, primaryAndSecondaryChoice, cptCodes, downloadCSV } from 'src/utils';
import { GroupAdminDashboard } from './components/Group admin/GroupAdminDashboard';
import { SystemAdminDashboard } from './components/Systemadmin/SystemAdminDashboard';
import { Grid, Menu } from '@mui/material';
import { setSmartPhrases } from 'src/redux/practices/practiceActions';
import { useUserConditionalRendering } from 'src/pages/common/hooks/useUserConditionalRendering';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { updatePrimaryAndSecondaryChoice } from 'src/redux/action/setUserHandler';

const ReadyToBilledReports = ({
  billedHandler,
  totalBillReports,
  csvButtonDisabled,
  createBillingCsvFile,
  combineFilteredReports,
  allBillReportFlagCheck,
  billedAllHandler,
}) => {
  const {
    user: { user },
  } = useSelector((state) => state);
  const [anchorElem, setAnchorElem] = useState(null);
  let open = Boolean(anchorElem);

  const handleClick = (event) => {
    setAnchorElem(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElem(null);
  };

  const id = open ? `simple-popover` : undefined;

  return (
    <Box sx={{ display: `inline-block` }}>
      <button
        onClick={handleClick}
        style={{
          backgroundColor: `rgb(22, 153, 197)`,
          fontWeight: `400`,
          color: `white`,
          borderRadius: `32px`,
          minWidth: `175px`,
          height: `35px`,
          fontSize: `12px`,
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems: `center`,
          paddingRight: `0px`,
        }}
      >
        <span className="mr-2">Ready to Bill Reports</span>
        <span
          style={{
            padding: `8px 15px 0px 10px`,
            borderRadius: `0px 32px 32px 0px`,
            backgroundColor: `#0c5b77`,
            height: `100%`,
            outline: `none`,
            position: `relative`,
          }}
        >
          {totalBillReports > 0 ? (
            <span
              style={{
                position: `absolute`,
                top: `-8px`,
                right: `-2px`,
                fontSize: `x-small`,
                padding: `1px 5px`,
                borderRadius: `50%`,
                background: `red`,
                color: `white`,
              }}
            >
              {totalBillReports}
            </span>
          ) : null}

          <i className="fa fa-file-text-o" style={{ fontSize: 16, color: `white` }}></i>
        </span>
      </button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorElem}
        onClose={handleClose}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `left`,
        }}
      >
        <form
          style={{
            minWidth: `17em`,
            marginLeft: !user.isProvider || user.isClinicalStaff ? `-115px` : `20px`,
            maxHeight: `12em`,
          }}
        >
          <div>
            <span id={css.headText}>TOTAL BILLING REPORTS ({totalBillReports}):</span>
            <span>
              <button
                type="button"
                onClick={() => createBillingCsvFile(`READY_TO_BILL`, null, null)}
                disabled={csvButtonDisabled}
                className="btn btn-outline-info btn-sm"
                style={{ fontSize: `9px`, marginLeft: `36px` }}
              >
                Download CSV
              </button>
            </span>
          </div>
          <thead id={css.customTableHead}>
            <tr>
              <th scope="col" style={{ width: `134px` }}>
                Patient
              </th>
              <th scope="col" style={{ width: `66px` }}>
                Month
              </th>
              <th scope="col" style={{ width: `40px` }}>
                Mark all as billed
              </th>
              {combineFilteredReports?.length ? (
                <th scope="col" style={{ width: `10px` }}>
                  <input type="checkbox" checked={allBillReportFlagCheck} onChange={(event) => billedAllHandler(event.target.checked)} />
                </th>
              ) : null}
            </tr>
          </thead>
          <hr className={css.horizentalRowReadyToBill} />
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <div style={{ width: `16em`, height: `5em` }}>
                {combineFilteredReports?.length ? (
                  <tbody id={css.customTableBody}>
                    {combineFilteredReports?.map((report) => {
                      return (
                        <tr key={report._id}>
                          <td style={{ width: `135px` }}>
                            <p style={{ fontSize: `10px` }} className="text-capitalize">
                              {`${capitalizeFirstLetter(report?.patient?.firstName)} ${capitalizeFirstLetter(report?.patient?.lastName)} `}
                              {` `}
                              {report?.reportStatus === `ADDENDUMED` ? <span className="badge badge-pill badge-info">A</span> : null}
                            </p>
                          </td>
                          <td style={{ width: `105px` }}>{momentWrapper(`${report.month}`, `YYYYMM`).format(`MMM YY`)}</td>
                          <td style={{}}>
                            <input
                              type="checkbox"
                              onChange={(e) => billedHandler(e, report._id, report?.isDuplicated ? { isAddendumBilled: true } : { isBilled: true })}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <h6 style={{ paddingTop: `30px`, textAlign: `center`, fontWeight: `bold` }}>No record Found</h6>
                )}
              </div>
            </table>
          </div>
        </form>
      </Menu>
    </Box>
  );
};

const ReadyToSignReports = ({ btnReadyToSign, totalReadyToSignReports, readyToSignReportLists, readyToSignReport }) => {
  return (
    <Box sx={{ display: `inline-block` }}>
      <button
        disabled={btnReadyToSign}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="dropdownMenuButton"
        data-target="dropdownnotifyButton"
        style={{
          backgroundColor: `rgb(22, 153, 197)`,
          fontWeight: `400`,
          color: `white`,
          borderRadius: `32px`,
          minWidth: `175px`,
          height: `35px`,
          fontSize: `12px`,
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems: `center`,
          paddingRight: `0px`,
        }}
      >
        <span className="mr-2">Ready to Sign Reports</span>
        <span
          style={{
            padding: `8px 15px 0px 10px`,
            borderRadius: `0px 32px 32px 0px`,
            backgroundColor: `#0c5b77`,
            height: `100%`,
            outline: `none`,
            position: `relative`,
          }}
        >
          {totalReadyToSignReports > 0 ? (
            <span
              style={{
                position: `absolute`,
                top: `-8px`,
                right: `-2px`,
                fontSize: `x-small`,
                padding: `1px 5px`,
                borderRadius: `50%`,
                background: `red`,
                color: `white`,
              }}
            >
              {totalReadyToSignReports}
            </span>
          ) : null}

          <i className="fa fa-file-text-o" style={{ fontSize: 16, color: `white` }}></i>
        </span>
      </button>
      <form
        className={`mt-2 dropdown-menu ${css.newUserDropDown}`}
        id="dropdownnotifyButton"
        aria-labelledby="dropdownMenuButton"
        style={{
          maxHeight: `12em`,
          //overflowY: 'scroll'
        }}
      >
        <p id={css.headText}>READY TO SIGN REPORTS ({totalReadyToSignReports}):</p>
        <thead id={css.customTableHead}>
          <tbody>
            <tr>
              <th scope="col" style={{ width: `196px` }}>
                Patient
              </th>
              <th scope="col" style={{ textAlign: `end` }}>
                Month
              </th>
            </tr>
          </tbody>
        </thead>
        <hr className={css.horizentalRow} />
        <div className="table-responsive">
          <table className="table table-borderless table-sm">
            <div style={{ width: `15em`, height: `5em` }}>
              {readyToSignReportLists?.length ? (
                <tbody id={css.customTableBody}>{readyToSignReport}</tbody>
              ) : (
                <h6 style={{ paddingTop: `30px`, textAlign: `center`, fontWeight: `bold` }}>No record Found</h6>
              )}
            </div>
          </table>
        </div>
      </form>
    </Box>
  );
};

const DashBoardWidgets = ({ monitoringTime, totalPatients, onlinePatients, adherenceArr, riskStratification, offlinePatients }) => {
  return (
    <Box sx={{ display: `flex`, gap: isDme() ? `60px` : `30px`, flexDirection: `column` }}>
      <StatsWidget totalPatients={totalPatients} onlinePatients={onlinePatients} offlinePatients={offlinePatients} />
      <Grid container spacing={2} sx={{ ...(isDme() && { display: `flex`, justifyContent: `center` }) }}>
        <Grid item xs={4} sm={4} md={6} lg={4} xl={4}>
          <AdherenceWidget adherenceArr={adherenceArr} />
        </Grid>
        <Grid item xs={4} sm={4} md={6} lg={4} xl={4}>
          <MonitoringRiskWidget riskStratification={riskStratification} />
        </Grid>
        {!isDme() && (
          <Grid item xs={4} sm={4} md={6} lg={4} xl={4}>
            <MonitoringWidget monitoringTime={monitoringTime} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const MuiSelectForPrimaryAndSecondary = () => {
  const switchedPrimaryAndSecondaryUser = useSelector((state) => state?.user?.primaryAndSecondaryChoice);

  const dispatch = useDispatch();

  // styling object for multiple select component
  let stylingObj = {
    borderRadius: `32px`,
  };

  return (
    <Box sx={{ display: `inline-block`, minWidth: `175px` }}>
      <MuiSelect
        controlProps={{ fullWidth: true, id: `primaryAndSecondaryChoice`, size: `small` }}
        label="Dashboard Type"
        isSimple={false}
        options={[...primaryAndSecondaryChoice]}
        optionKey={`label`}
        optionValue={`value`}
        selectProps={{
          label: `Dashboard Type`,
          id: `primaryAndSecondaryChoice`,
          name: `selectPrimaryAndSecondaryChoice`,
          onChange: (event) => {
            dispatch(updatePrimaryAndSecondaryChoice(event?.target?.value));
          },
          value: switchedPrimaryAndSecondaryUser,
          sx: {
            ...stylingObj,
          },
        }}
      />
    </Box>
  );
};

const SuperAdminDashBoard = () => {
  const {
    user: { user },
  } = useSelector((state) => state);
  return (
    <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <Box sx={{ display: `flex`, justifyContent: `space-between`, flexDirection: { xs: `column`, sm: `row` }, gap: `10px` }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{ fontWeight: `normal`, textTransform: `capitalize`, fontSize: 24, textAlign: { xs: `center`, sm: `left` } }}
        >
          Welcome,{` `}
          <span style={{ fontWeight: `600` }}>
            {capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.lastName)}
          </span>
        </Typography>
      </Box>
      <AdministrationPractice />
    </Box>
  );
};

const Container = ({ children }) => {
  return <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>{children}</Box>;
};

const NestedContainer = ({ children }) => {
  const {
    user: { user },
  } = useSelector((state) => state);
  return (
    <Box sx={{ display: `flex`, justifyContent: `space-between`, flexDirection: { xs: `column`, sm: `row` }, gap: `10px` }}>
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        sx={{ fontWeight: `normal`, textTransform: `capitalize`, fontSize: 24, textAlign: { xs: `center`, sm: `left` } }}
      >
        Welcome,{` `}
        <span style={{ fontWeight: `600` }}>
          {capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.lastName)}
        </span>
      </Typography>
      {children}
    </Box>
  );
};

const ProviderDashBoard = (props) => {
  const {
    totalBillReports,
    csvButtonDisabled,
    createBillingCsvFile,
    combineFilteredReports,
    allBillReportFlagCheck,
    billedHandler,
    billedAllHandler,
    dashBoardWidgetProps,
  } = props;
  return (
    <Container>
      <NestedContainer>
        <Box sx={{ display: `flex`, flexWrap: `wrap`, gap: `12px` }}>
          <MuiSelectForPrimaryAndSecondary />
          <ReadyToSignReports {...props} />
          <ReadyToBilledReports
            totalBillReports={totalBillReports}
            csvButtonDisabled={csvButtonDisabled}
            createBillingCsvFile={createBillingCsvFile}
            combineFilteredReports={combineFilteredReports}
            allBillReportFlagCheck={allBillReportFlagCheck}
            billedHandler={billedHandler}
            billedAllHandler={billedAllHandler}
          />
        </Box>
      </NestedContainer>
      <DashBoardWidgets {...dashBoardWidgetProps} />
    </Container>
  );
};

const ClinicalStaffDashBoard = (props) => {
  return (
    <Container>
      <NestedContainer>
        <MuiSelectForPrimaryAndSecondary />
        <></>
      </NestedContainer>
      <DashBoardWidgets {...props} />
    </Container>
  );
};

const BillerDashBoard = (props) => {
  return (
    <Container>
      <NestedContainer>
        <></>
      </NestedContainer>
      <BillerRoleTable {...props} />
    </Container>
  );
};

export const Dashboard = () => {
  const { user: parentUser, resmed } = useSelector((state) => state);
  const switchedPrimaryAndSecondaryUser = useSelector((state) => state?.user?.primaryAndSecondaryChoice);
  const { user } = parentUser;
  const { importNewPatientSuccessfully } = resmed;
  let history = useHistory();
  let readyToSignReport = null;
  const { _practiceId: practiceId } = useSelector((state) => state?.user?.user);
  const [readyToSignReportLists, setReadyToSignReportLists] = useState([]);
  const [totalReadyToSignReports, setTotalReadyToSignReports] = useState(0);
  const [totalBillReports, setTotalBillReports] = useState(0);
  const [btnReadyToSign, setBtnReadyToSign] = useState(true);
  const [totalPatients, setTotalPatients] = useState(0);
  const [monitoringTime, setMonitoringTime] = useState([]);
  const [riskStratification, setRiskStratification] = useState([]);
  const [adherenceArr, setAdherenceArr] = useState([]);
  const [onlinePatients, setOnlinePatients] = useState(0);
  const [offlinePatients, setOfflinePatients] = useState(0);
  const [combineFilteredReports, setCombineFilteredReports] = useState([]);
  const [allBillReportFlagCheck, setAllBillReportFlagCheck] = useState(false);

  // System admin usage hook
  // const [realTimeDataAvailableFlag, setRealTimeDataAvailableFlag] = useState(realTimeDataAvailable);

  // CSV
  const [csvButtonDisabled, setCsvButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // -> Ready to sign Report
    if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
      if (user.isProvider) {
        getRequest(GET_REPORTS_BY_STATUS + `READY_TO_SIGN`)
          .then((response) => {
            setBtnReadyToSign(false);
            setTotalReadyToSignReports(response.data.reports.length);
            setReadyToSignReportLists(response.data.reports);
          })
          .catch(() => { });
      }
    }

    if (!user?.isSystemAdministrator && !user?.isGroupAdmin) {
      let payload = {
        count: false,
      };
      let route = `${SMART_PHRASE_LIST}${practiceId}?pageNumber=${payload?.page}&pageSize=${payload?.recordPerPage}&fieldName=${payload?.sortKey}&Sortorder=${payload?.sortOrder}`;

      getRequest(route, payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setSmartPhrases(response?.data?.phrases?.phrases));
          }
        })
        .catch((e) => {
          printInBrowser({ key: `hasError`, value: e });
        });
    }

    // -> Ready to Bill Report

    if ((user?.isSuperAdmin && user?.isProvider) || user?.isProvider) {
      getRequest(GET_REPORTS_BY_STATUS + `READY_TO_BILL`)
        .then((response) => {
          setCombineFilteredReports(response?.data?.reports);
          setTotalBillReports(response?.data?.reports?.length);
        })
        .catch(() => { });
    }
  }, [importNewPatientSuccessfully]);

  useEffect(() => {
    if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
      if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller) {
        // DASHBOARD ANALYTICS
        postRequest(DASHBOARD_ANALYTICS, {
          startDate: momentWrapper().startOf(`month`).toDate(),
          endDate: momentWrapper().toDate(),
          assignedAs: switchedPrimaryAndSecondaryUser,
        })
          .then((response) => {
            setMonitoringTime(response.data.dashboardAnalytics.monitoringTime);
            setRiskStratification(response.data.dashboardAnalytics.riskStratification);
            setAdherenceArr(response.data.dashboardAnalytics.adherenceArr);
            setTotalPatients(response?.data?.dashboardAnalytics?.totalPatients);
          })
          .catch(() => { });
      }
    }
  }, [switchedPrimaryAndSecondaryUser]);

  useEffect(() => {
    if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
      if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isBiller) {
        getRequest(`${GET_PATIENT_ONLINE_OFFLINE_STATUS}?assignedAs=${switchedPrimaryAndSecondaryUser}`)
          .then((response) => {
            setOnlinePatients(response.data?.numberOfOnlinePatients);
            setOfflinePatients(response.data?.numberOfOfflinePatients);
          })
        // .catch(() => { });
      }
    }
  }, [importNewPatientSuccessfully, switchedPrimaryAndSecondaryUser]);

  const createBillingCsvFile = (status, startDate, endDate, reportStatus) => {

    setCsvButtonDisabled(true);
    let reportsArray = [];
    postRequest(BILLING_CSV_FILE, { startDate, endDate, status, reportStatus })
      .then((response) => {
        reportsArray = response?.data?.reports;
        setCsvButtonDisabled(false);
        const csvObject = reportsArray.map((report) => {
          let obj = {};
          obj[`Medical Record Number`] = report?.patient?.MRN ? report?.patient?.MRN : ` `;
          obj[`Last Name`] = capitalizeFirstLetter(report?.patient?.lastName);
          obj[`First Name`] = capitalizeFirstLetter(report?.patient?.firstName);
          obj[`Date of Birth`] = momentWrapper(report?.patient?.dateOfBirth).format(`MM-DD-YYYY`);
          obj[`Provider Last Name`] = capitalizeFirstLetter(report?._providerId?.lastName);
          obj[`Report Month`] = momentWrapper(report?.month.toString(), `YYYYMM`).format(`MMM YYYY`);

          let minutes = Math.floor((report?.totalTime % 3600) / 60);
          let seconds = report?.totalTime % 60;
          let hours = Math.floor(report?.totalTime / 3600);

          minutes = minutes.toString().length === 1 ? `0` + minutes : minutes;
          seconds = seconds.toString().length === 1 ? `0` + seconds : seconds;
          hours = hours.toString().length === 1 ? `0` + hours : hours;

          obj[`Time Spent (h:m:s)`] = hours + `:` + minutes + `:` + seconds;
          obj[`Interactive Communication`] = report?.patient?.interactiveCommunication ? `Yes` : `No`;
          obj[`Days Spent on device`] = report?.usage ? report?.usage : 0;
          obj[`Date of Enrollment`] = report?.patient?.createdAt ? momentWrapper(report?.patient?.createdAt).format(`MM-DD-YYYY`) : ``;
          obj[`Insurance Company`] = report?.patient?.insuranceCompany ? report?.patient?.insuranceCompany : ``;
          let reportDate = report?.month;
          let enrollmentDate = new Date(report?.patient?.createdAt);

          // for getting report date and year 6/4/2022

          let reportDateMonth = 1 + momentWrapper(reportDate, `YYYY/MM/DD`).month();
          let enrollmentDateMonth = 1 + momentWrapper(report?.patient?.createdAt, `YYYY/MM/DD`).month();
          let reportDateYear = reportDate.toString().substr(0, 4);
          let enrollmentDateYear = enrollmentDate.getFullYear();
          let sameBill = 0;
          if (parseInt(reportDateMonth) === parseInt(enrollmentDateMonth) && parseInt(reportDateYear) === parseInt(enrollmentDateYear)) {
            sameBill = 1;
          }
          const { fiveSev, fiveFour, fiveEight } = cptCodes({ usage: report?.usage, totalTime: report?.totalTime, interactiveCommunication: report?.patient?.interactiveCommunication })
          obj[`Primary ICD10`] = report?.patient?.primaryDiagnosisId ? report?.patient?.primaryDiagnosisId : ``;
          obj[`Secondary ICD10`] = report?.patient?.secondaryDiagnosisId ? report?.patient?.secondaryDiagnosisId : ``;
          obj[` ` + `99453*`] = sameBill;
          obj[` ` + `99454*`] = fiveFour;
          obj[` ` + `99457*`] = fiveSev;
          obj[` ` + `99458*`] = fiveEight;

          return obj;
        });


        let spaceObj = {};
        spaceObj[`Last Name`] = ``;
        spaceObj[`First Name`] = ``;
        csvObject.push(spaceObj);
        spaceObj[`Report Month`] = ``;
        csvObject.push(spaceObj);
        csvObject.push(spaceObj);

        let str = {};
        str[
          `Last Name`
        ] = `*The Monitair program may organize arrange and present coding data based solely on the information typed or scanned into the Monitair software by an Authorized User. Client and other `;
        let str1 = {};
        str1[
          `Last Name`
        ] = `Authorized Users shall be solely responsible for coding any medical service provided by them and for the maintenance and production of all documentation required to support such coding`;

        csvObject.push(str);
        csvObject.push(str1);
        let csv = ``;
        let header = Object.keys(csvObject[0]).join(`,`);
        let values = csvObject
          .map((o) => {
            return Object.values(o).join(`,`);
          })
          .join(`\n`);

        csv += header + `\n\n` + values;

        downloadCSV(csv, `/Billing Reports.csv`); // DOWNLOAD CSV
      })
      .catch((e) => {
        setCsvButtonDisabled(false);

        console.log(e, `whatiserror`)
      });
  };




  // DOWNLOAD C

  // HANDLING CHECKBOX
  const billedHandler = (e, reportId, actionObj) => {
    e.target.disabled = true;
    patchRequest(UPDATE_REPORT_BILL_STATUS + reportId, {})
      .then(() => {
        if (actionObj.isAddendumBilled) {
          // Looking for addendum report
          const index = combineFilteredReports.findIndex((report) => report._id === reportId && report.hasOwnProperty(`isDuplicated`));
          const _tempArray = [...combineFilteredReports];
          if (index > -1) {
            _tempArray.splice(index, 1);
            setTotalBillReports(_tempArray.length);
            setCombineFilteredReports(_tempArray);
          }
        } else {
          const index = combineFilteredReports.findIndex((report) => report._id === reportId && !report.hasOwnProperty(`isDuplicated`));
          const _tempArray = [...combineFilteredReports];
          if (index > -1) {
            _tempArray.splice(index, 1);
            setTotalBillReports(_tempArray.length);
            setCombineFilteredReports(_tempArray);
          }
        }
      })
      .catch(() => { });
  };
  // HANDLE ALL BILLED LIST UPDATE
  const billedAllHandler = () => {
    setAllBillReportFlagCheck(true);
    const billedId = combineFilteredReports?.map((report) => report._id);
    Swal.fire({
      title: `Are you sure?`,
      text: `You want to bill all reports!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonColor: `#3085d6`,
      cancelButtonColor: `#d33`,
      confirmButtonText: `Yes, bill it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        const payLoad = { reportsIds: billedId };
        patchRequest(UPDATE_ALL_REPORT_BILL_STATUS, payLoad)
          .then(() => {
            setCombineFilteredReports([]);
            setTotalBillReports(0);
            Swal.fire(`Success!`, `All Reports Billed`, `success`);
          })
          .catch(() => { });
      } else {
        setAllBillReportFlagCheck(false);
      }
    });
  };

  // System admin Dashboard code
  // useEffect(() => {
  //   if (realTimeDataAvailable) {
  //     if (realTimeDataAvailable.apiStatus === `available`) {
  //       setRealTimeDataAvailableFlag(true);
  //     }
  //     if (realTimeDataAvailable.apiStatus === `unavailable`) {
  //       setRealTimeDataAvailableFlag(false);
  //     }
  //     if (realTimeDataAvailable.apiStatus === `disabled`) {
  //       setRealTimeDataAvailableFlag(false);
  //     }
  //   }
  // }, [realTimeDataAvailable]);

  //   if (realTimeDataAvailable?.apiStatus === `disabled` || realTimeDataAvailable?.apiStatus === `unavailable`) {
  //     newSocket.emit(`changeResmedAPIStatus`, {
  //       apiStatus: `available`,
  //     });
  //   }
  // };
  // end sysAdmin Dashboard

  // Ready to sign Reports First Chip to show provider and super admin
  readyToSignReport = readyToSignReportLists.map((report) => (
    <tr key={report._id}>
      <td style={{ width: `197px` }}>
        <p
          key={report._id}
          style={{
            fontSize: `10px`,
            cursor: `pointer`,
            color: `rgb(22, 153, 197)`,
          }}
          className="text-capitalize"
          onClick={() => {
            history.push({
              pathname: `/patient`,
              reportId: report?._id,
              reportPatientID: report?.patient?._id,
              startDateOfReport: report?.startDate,
              endDateOfReport: report?.endDate,
              reportMonth: report?.month,
            });
          }}
        >
          {`${capitalizeFirstLetter(report.patient?.firstName)} ${capitalizeFirstLetter(report.patient?.lastName)}`}
          {` `}
          {report?.reportStatus === `READY_TO_ADDENDUM` ? <span className="badge badge-pill badge-info">A</span> : null}
        </p>
      </td>
      <td style={{ textAlign: `end` }}>{momentWrapper(`${report.month}`, `YYYYMM`).format(`MMM YY`)}</td>
    </tr>
  ));

  const dashBoardWidgetProps = {
    totalPatients,
    monitoringTime,
    riskStratification,
    adherenceArr,
    onlinePatients,
    offlinePatients,
  };

  const providerDashBoardPros = {
    totalBillReports: totalBillReports,
    csvButtonDisabled: csvButtonDisabled,
    createBillingCsvFile: createBillingCsvFile,
    combineFilteredReports: combineFilteredReports,
    allBillReportFlagCheck: allBillReportFlagCheck,
    billedHandler: billedHandler,
    billedAllHandler: billedAllHandler,
    btnReadyToSign: btnReadyToSign,
    totalReadyToSignReports: totalReadyToSignReports,
    readyToSignReportLists: readyToSignReportLists,
    readyToSignReport: readyToSignReport,
    dashBoardWidgetProps: dashBoardWidgetProps,
  };
  const billerDashBoardProps = {
    billedHandler: billedHandler,
    billedAllHandler: billedAllHandler,
    csvButtonDisabled: csvButtonDisabled,
    createBillingCsvFile: createBillingCsvFile,
    allBillReportFlagCheck: allBillReportFlagCheck,
  };

  const renderDashBoard = useUserConditionalRendering({
    superAdminAndProvider: <ProviderDashBoard {...providerDashBoardPros} />,
    superAdminAndClinicalStaff: <ClinicalStaffDashBoard {...dashBoardWidgetProps} />,
    superAdmin: <SuperAdminDashBoard />,
    provider: <ProviderDashBoard {...providerDashBoardPros} />,
    clinicalStaff: <ClinicalStaffDashBoard {...dashBoardWidgetProps} />,
    biller: <BillerDashBoard {...billerDashBoardProps} />,
    systemAdmin: <SystemAdminDashboard />,
    groupAdmin: <GroupAdminDashboard />,
  });

  return renderDashBoard;
};