import React from 'react';
import { EFaxTable } from './eFaxTable';
import Box from '@mui/material/Box';

export const EFax = () => {
  return (
    <>
      <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
        <EFaxTable />
      </Box>
    </>
  );
};
