import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './editPractice.module.css';
import { patchRequest, PRACTICE_UPDATE } from '../../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import moment from 'moment-timezone';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { setUserHandler, updatePracticeLogoPath } from 'src/redux/action/setUserHandler';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { faxNumberMask, faxNumberValidation, usStateName } from 'src/utils';
import { emailRegex, phoneRegex } from 'src/regexes';
import { Box, Grid } from '@mui/material';
import { FileInput, InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { ConfirmationWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';
import { EFaxNumberForPractice } from 'src/pages/home/modules/eFax/eFaxNumberForPractice';
import styling from 'src/pages/common/components/wrapper/components/avator/editUser/editUser.module.css';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const EditPractice = ({ data, setData, show, setShow }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state?.user?.user?.systemRoles);
  const [openTimeZoneConfirmation, setOpenTimeZoneConfirmation] = useState(false);
  const [errorImageUpload, setErrorImageUpload] = useState(``);
  const [practiceName, setPracticeName] = useState(``);
  const [timeZone, setTimeZone] = useState();
  const timeZoneRef = useRef({ current: {} });
  const [address, setAddress] = useState(``);
  const [city, setCity] = useState(``);
  const [state, setState] = useState(``);
  const [zip, setZip] = useState(``);
  const [practicePhoneNumber, setPracticePhoneNumber] = useState(``);
  const [faxNumber, setFaxNumber] = useState(``);
  const [faxError, setFaxError] = useState(``);
  const [email, setEmail] = useState(``);
  const [practiceLogo, setPracticeLogo] = useState(``);
  const [loading, setLoading] = useState(false);
  const [inputsToEdit, setInputsToEdit] = useState({});
  const [eFaxFieldStatus, setEFaxFieldStatus] = useState(``);
  const [eFaxStatus, setEFaxStatus] = useState('inactive'); // State for 'active' or 'inactive'
  const [eFaxNumber, setEFaxNumber] = useState(``);

  const inputToEditChangeHandler = (key, value) => {
    setInputsToEdit((p) => ({ ...p, [key]: value }));
  };

  const resetValues = () => {
    setPracticeName(``);
    setPracticeLogo(``);
    setAddress(``);
    setCity(``);
    setState(``);
    setTimeZone(``);
    setZip(``);
    setPracticePhoneNumber(``);
    setEmail(``);
    setFaxNumber(``);
    setInputsToEdit({});
    timeZoneRef.current = {};
    setFaxError(``);
  };
  const handleClose = () => {
    resetValues();
    setShow(false);
  };

  useEffect(() => {
    if (data) {
      setPracticeName(data.practiceName);
      setPracticeLogo(data.practiceLogoPath);
      setAddress(data.line1);
      setCity(data.city);
      setState(data.state);
      setTimeZone(data.timezone);
      setZip(data.zipCode);
      setPracticePhoneNumber(data?.practicePhoneNumber ? data?.practicePhoneNumber : ``);
      setEmail(data.email);
      setFaxNumber(data.faxNumber);
      setEFaxFieldStatus(data.eFaxStatus === 'active');
      setEFaxStatus(data.eFaxStatus);
      setEFaxNumber(data.eFaxNumber);
    }
    return () => {
      resetValues();
    };
  }, [data]);

  // A function to update the practice image when switching practices
  // It takes practice ID and response as input parameters
  const updatePracticeImageOnSwitchingPractice = (practiceId, response) => {
    // Dispatches an action to update the practice logo path with practice ID, current user, and the new practice logo path
    dispatch(updatePracticeLogoPath({ practiceId, currentUser, practiceLogo: response?.data?.practice?.practiceLogoPath }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validImageTypes = [`image/jpeg`, `image/png`];
    const formData = new FormData();
    if (errorImageUpload) {
      errorToast(`Height and Width must not exceed 100*270`);
      return;
    }
    if (faxError) {
      errorToast(`Error Field Validation!`);
      return;
    }
    if (Object.keys(inputsToEdit)?.length) {
      for (let key in inputsToEdit) {
        formData.append(key, inputsToEdit[key]);
        if (key === `zipCode`) {
          let regex = /^\d{5}(?:[-\s]\d{4})?$/;
          if (!regex.test(inputsToEdit[key])) {
            errorToast(`Invalid ZIP code`);
            return;
          }
        }
        if (key === `email`) {
          if (!emailRegex.test(inputsToEdit[key])) {
            errorToast(`Invalid Email Address`);
            return;
          }
        }
        if (key === `practicePhoneNumber`) {
          if (!phoneRegex.test(inputsToEdit[key])) {
            errorToast(`Invalid Phone Number`);
            return;
          }
        }
        if (key === `faxNumber`) {
          if (!phoneRegex.test(inputsToEdit[key])) {
            errorToast(`Invalid Fax Number`);
            return;
          }
        }
        if (key === `eFaxNumber`) {
          if (!phoneRegex.test(inputsToEdit[key])) {
            errorToast(`Invalid EFax Number`);
            return;
          }
        }
        if (practiceLogo.length === null) {
          if (key === `practiceLogo`) {
            if (!validImageTypes.includes(inputsToEdit[key].type) || inputsToEdit[key].size > MAX_FILE_SIZE) {
              errorToast(`Only JPEG, PNG are allowed & Max allowed file size is 5 MB`);
              return;
            }
          }
        }
      }
      setLoading(true);
      patchRequest(PRACTICE_UPDATE + `/` + data._id, formData)
        .then((response) => {
          setData(response?.data?.practice);
          dispatch(
            setUserHandler({ ...user.user, ...(inputsToEdit?.practiceLogo ? { practiceLogoPath: response?.data?.practice?.practiceLogoPath } : {}) })
          );
          updatePracticeImageOnSwitchingPractice(data._id, response);
          successToast(`Practice edit successfully`);
          setShow(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      errorToast(`Please Edit Some Entries!`);
    }
  };

  const valiDateImg = (e) => {
    let fileUpload = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(fileUpload);
    reader.onload = function (e) {
      let image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (height > 100 && width > 270) {
          errorToast(`Height and Width must not exceed 100*270`);
          setErrorImageUpload(true);
          return false;
        }
        successToast(`Uploaded image has valid Height and Width.`);
        setErrorImageUpload(false);
        inputToEditChangeHandler(`practiceLogo`, fileUpload);
        return true;
      };
    };
  };

  const spreadTimeZoneConfirmationProps = useMemo(() => {
    return {
      title: `Time Zone Alteration!`,
      description: `Any type of alteration in the practice time zone will cause your account to be logged out.`,
      open: openTimeZoneConfirmation,
      successFunc: () => {
        setOpenTimeZoneConfirmation((p) => !p);
        inputToEditChangeHandler(`timezone`, timeZoneRef.current.value);
        setTimeZone(timeZoneRef.current.value);
      },
      cancelFunc: () => setOpenTimeZoneConfirmation((p) => !p),
      successText: `Yeah change the time zone!`,
      cancelText: `No`,
    };
  }, [openTimeZoneConfirmation]);

  const adminPhoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPracticePhoneNumber(temp);
    inputToEditChangeHandler(`practicePhoneNumber`, temp);
  };

  const handleEFaxFieldStatus = (event) => {
    const { checked } = event.target;
    setEFaxFieldStatus(checked);
    setEFaxStatus(checked ? 'active' : 'inactive');
    setInputsToEdit((prevInputs) => ({
      ...prevInputs,
      eFaxStatus: checked ? 'active' : 'inactive',
    }));
  };

  return (
    <>
      <CustomizedDialogs
        title="Edit Practice"
        open={show}
        setOpen={() => handleClose()}
        size="sm"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 2 }}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputField
                  fullWidth={true}
                  label="Practice Name*"
                  size="small"
                  onChange={(event) => {
                    setPracticeName(event.target.value);
                    inputToEditChangeHandler(`practiceName`, event.target.value);
                  }}
                  value={practiceName}
                  name="practiceName"
                  type="text"
                  id="practiceName"
                />
              </Grid>
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <InputField
                  fullWidth={true}
                  label="Address*"
                  size="small"
                  onChange={(event) => {
                    setAddress(event.target.value);
                    inputToEditChangeHandler(`line1`, event.target.value);
                  }}
                  value={address}
                  type="text"
                  name="line1"
                  id="line1"
                />
              </Grid>

              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <InputField
                  fullWidth={true}
                  label="City*"
                  size="small"
                  type="text"
                  onChange={(event) => {
                    inputToEditChangeHandler(`city`, event.target.value);
                    setCity(event.target.value);
                  }}
                  name="city"
                  id="city"
                  value={city}
                />
              </Grid>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <MuiSelect
                  controlProps={{ size: `small`, fullWidth: true, id: `practiceState` }}
                  label="State*"
                  isSimple={false}
                  options={[...usStateName]}
                  optionKey={`name`}
                  optionValue={`abbreviation`}
                  selectProps={{
                    label: `State*`,
                    id: `practiceState`,
                    name: `state`,
                    onChange: (event) => {
                      inputToEditChangeHandler(`state`, event.target.value);
                      setState(event.target.value);
                    },
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: `420px`, // set the maximum height of the options
                          width: `280px`, // set the width of the options
                        },
                      },
                    },
                    value: state,
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <InputField
                  fullWidth={true}
                  label="ZIP Code*"
                  size="small"
                  onChange={(event) => {
                    inputToEditChangeHandler(`zipCode`, event.target.value);
                    setZip(event.target.value);
                  }}
                  value={zip}
                  name="zipCode"
                  type="text"
                  id="zip"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputField
                  fullWidth={true}
                  label="Phone*"
                  size="small"
                  onChange={(event) => adminPhoneMask(event.target.value)}
                  value={practicePhoneNumber}
                  type="text"
                  id="phone"
                  name="practicePhoneNumber"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <ConfirmationWrapper {...spreadTimeZoneConfirmationProps}>
                  <MuiSelect
                    controlProps={{ size: `small`, fullWidth: true, id: `timeZOnePractice` }}
                    label="Practice Time Zone"
                    isSimple={true}
                    options={[...moment.tz.names()]}
                    selectProps={{
                      label: `Practice Time Zone`,
                      id: `timeZOnePractice`,
                      name: `timezone`,
                      onChange: (event) => {
                        let hasSelected = timeZoneRef.current?.value;
                        if (!hasSelected) {
                          setOpenTimeZoneConfirmation((p) => !p);
                        } else {
                          inputToEditChangeHandler(`timezone`, event.target.value);
                          setTimeZone(event.target.value);
                        }
                        timeZoneRef.current[`value`] = event.target.value;
                      },
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: `420px`, // set the maximum height of the options
                            width: `280px`, // set the width of the options
                          },
                        },
                      },
                      value: timeZone,
                    }}
                  />
                </ConfirmationWrapper>
              </Grid>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputField
                  fullWidth={true}
                  label="Email*"
                  size="small"
                  onChange={(event) => {
                    inputToEditChangeHandler(`email`, event.target.value);
                    setEmail(event.target.value);
                  }}
                  value={email}
                  type="email"
                  name="email"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <FileInput
                  inputProps={{
                    onChange: (e) => valiDateImg(e),
                    accept: `.jpg, .jpeg, .png`,
                    id: `LogoFile`,
                    name: `practiceLogo`,
                  }}
                  fileBtnProps={{
                    endIcon: <UploadFileRoundedIcon sx={{ color: `white` }} />,
                    fullWidth: true,
                    variant: `contained`,
                    sx: { backgroundColor: `#1699c5` },
                    component: `span`,
                  }}
                />
              </Grid>


              <Grid item xs={6} sm={6} lg={6} md={6}>
                <label>EFax</label>
              </Grid>
              <Grid item xs={6} sm={6} lg={6} md={6}>
                <label id={styling.switch}>
                  <input
                    type="checkbox"
                    id={styling.switchInput}
                    checked={eFaxFieldStatus}
                    onChange={handleEFaxFieldStatus}
                  />
                  <span id={styling.switchLabel} data-on="On" data-off="Off"></span>
                  <span id={styling.switchHandle}></span>
                </label>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputField
                  fullWidth={true}
                  size="small"
                  label="Fax Number"
                  type="text"
                  onChange={(event) =>
                    faxNumberMask({
                      value: event.target.value,
                      setValue: (val) => {
                        setFaxNumber(val);
                        inputToEditChangeHandler(`faxNumber`, val);
                      },
                    })
                  }
                  onBlur={() => {
                    if (faxNumber) {
                      let hasError = !faxNumberValidation(faxNumber);
                      if (hasError) {
                        setFaxError(`Invalid Fax Number`);
                      } else {
                        setFaxError(``);
                      }
                    }
                  }}
                  error={!!faxError}
                  helperText={faxError}
                  name="faxNumber"
                  id="faxNumber"
                  value={faxNumber}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                {eFaxStatus === `active` && <EFaxNumberForPractice setInputsToEdit={setInputsToEdit} setEFaxNumber={setEFaxNumber} eFaxNumber={eFaxNumber} />}
              </Grid>

            </Grid>
          </Box >
          <hr style={{ margin: 20 }} />
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <button className="btn btn-outline-info" disabled={loading} type="submit" id={css.addNewUserModalButton}>
              Edit Practice
            </button>
          </div>
        </form >
        {loading && <PulseSpinner />}
      </CustomizedDialogs >
    </>
  );
};