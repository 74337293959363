import React, { useState } from 'react';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { faxNumberMask, faxNumberValidation } from 'src/utils';

export const EFaxNumberForPractice = ({ setInputsToEdit, eFaxNumber, setEFaxNumber }) => {
  const [eFaxError, setEFaxError] = useState(``);

  const inputToEditChangeHandler = (key, value) => {
    setInputsToEdit((prevInputs) => ({
      ...prevInputs,
      [key]: value,
    }));
  };

  return (
    <InputField
      fullWidth={true}
      size="small"
      label="EFax Number"
      type="text"
      onChange={(event) =>
        faxNumberMask({
          value: event.target.value,
          setValue: (val) => {
            setEFaxNumber(val);
            inputToEditChangeHandler(`eFaxNumber`, val);
          },
        })
      }
      onBlur={() => {
        if (eFaxNumber) {
          let hasError = !faxNumberValidation(eFaxNumber);
          if (hasError) {
            setEFaxError(`Invalid EFax Number`);
          } else {
            setEFaxError(``);
          }
        }
      }}
      error={!!eFaxError}
      helperText={eFaxError}
      name="eFaxNumber"
      id="eFaxNumber"
      value={eFaxNumber}
    />
  );
};
