import React, { useEffect, useState } from 'react';
import css from './practiceInfo.module.css';
import avatar from '../../../../../../../assets/hospital.jpg';
import { EditPractice } from '../editPractice/editPractice';
import { getRequest, PRACTICE_INFO_URL, PRACTICE_MAIN_ADMIN_INFO_URL } from '../../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { Fade } from '@mui/material';
import { capitalizeFirstLetter } from 'src/utils';

export const PracticeInfo = () => {

  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRequest(PRACTICE_INFO_URL)
      .then((res) => {
        setData(res.data.practice);
        getRequest(PRACTICE_MAIN_ADMIN_INFO_URL)
          .then((res) => {
            setUser(res.data.admin);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div id={css.practiceContainer} style={{ position: `relative` }}>
        <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
          <table className="table table-striped">
            <thead id={css.head}>
              <tr>
                <td id={css.headTextContainer} colSpan={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <td style={{ border: `1px solid gainsboro` }}>
                      <Fade in={data?.practiceLogoPath || avatar}>
                        <img src={data?.practiceLogoPath ? data?.practiceLogoPath : avatar} alt="profile" height="41" width="127" />
                      </Fade>
                    </td>

                    <td className={css.customCol6} style={{ border: `none` }}>
                      <button id={css.editButton} className="btn btn-info h-50" onClick={() => setShow(true)}>
                        EDIT
                      </button>
                    </td>

                  </div>
                </td>
              </tr>
              {/* <tr>
              <td className="ml-5 mb-5">
                <Image src="https://robohash.org/108.162.219.96.png" rounded />
              </td>
            </tr> */}
            </thead>
            <tbody id={css.body}>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Practice Name:
                </th>
                <td colSpan={5}>{capitalizeFirstLetter(data.practiceName)}</td>
              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Address:
                </th>
                <td className={css.customCol2}>
                  <Fade in={data?.line1}>
                    <span>{data?.line1}</span>
                  </Fade>
                </td>
                <td className={css.customCol3}>
                  <Fade in={data?.city}>
                    <span>{data?.city}</span>
                  </Fade>
                </td>
                <td className={css.customCol4}>
                  <Fade in={data?.state}>
                    <span>{data?.state}</span>
                  </Fade>
                </td>
                <td className={css.customCol5}>
                  <Fade in={data?.zipCode}>
                    <span>{data?.zipCode}</span>
                  </Fade>
                </td>
              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Contact:
                </th>

                <td>
                  <div style={{ marginBottom: '10px' }}>
                    Office Phone: {data?.practicePhoneNumber}
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    Office Fax: {data?.faxNumber}
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    Office Email: {data?.email}
                  </div>
                </td>

                <td>
                  <div style={{ marginBottom: '10px' }}>
                    Twilio Number: {data?.phoneNumber}
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    eFax Number: {data?.eFaxNumber}
                  </div>
                </td>

                <td>

                </td>
                <td colSpan={4}>

                </td>

              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Admin:
                </th>
                <td>
                  <Fade in={user?.lastName}>
                    <span>{capitalizeFirstLetter(user?.lastName)}</span>
                  </Fade>

                  <Fade in={user?.firstName}>
                    <span> {capitalizeFirstLetter(user?.firstName)},</span>
                  </Fade>

                  <Fade in={user?.title}>
                    <span> {user?.title}</span>
                  </Fade>
                </td>

                <td>
                  <Fade in={user?.phoneNumber}>
                    <span> {user?.phoneNumber}</span>
                  </Fade>
                </td>

                <td>
                  <Fade in={user?.email}>
                    <span> {user?.email}</span>
                  </Fade>
                </td>

                <td colSpan={3}>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {loading ? <PuffSpinner /> : null}
      </div >
      {show && <EditPractice show={show} setShow={setShow} data={data} setData={setData} />
      }
    </>
  );
}